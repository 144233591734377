import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable, of } from 'rxjs';
import { mergeMap, tap, map, switchMap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State, toODataString } from '@progress/kendo-data-query';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';

import { TicketDetail } from './ticket-detail.model';
import { UtilService } from 'src/app/shared/util.service';
import { GridService } from 'src/app/shared/grid.service';

import { TicketDetailEditPopupComponent } from './ticket-detail-edit-popup/ticket-detail-edit-popup.component';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class TicketDetailService {
  private gridService = inject(GridService);
  private dialog!: DialogRef;

  constructor(private http: HttpClient, private utilService: UtilService, private dialogService: DialogService) {}

  list(ticketId: number): Observable<TicketDetail[]> {
    let uri = `${apiUrl}services/TicketDetails?$filter=ticketId eq ${ticketId}`;
    return this.http.get<TicketDetail[]>(uri).pipe(
      mergeMap((response: any) => {
        return of(response.value);
      })
    );
  }

  read(id: number): Observable<TicketDetail> {
    return this.http.get<TicketDetail>(apiUrl + 'services/TicketDetails/' + id, {}).pipe(
      tap((response: any) => {
        //this.ticket.next(response);
      })
    );
  }

  create(ticketDetail: any): Observable<any> {
    return this.http.post<any>(apiUrl + 'services/TicketDetails/' + ticketDetail.ticketId, ticketDetail).pipe(
      tap((response: any) => {
        //this.ticket.next(response); // TODO: réactiver quand l'API va me retourner le record comme dans un get
        // TODO : refresh de la variable this.tickets avec le nouveau record
      })
    );
  }

  update(ticketDetail: any): Observable<any> {
    return this.http.put<any>(`${apiUrl}services/TicketDetails/${ticketDetail.ticketId}/${ticketDetail.lineNumber}/`, ticketDetail).pipe(
      tap((response: any) => {
        //this.ticket.next(response); // TODO: réactiver quand l'API va me retourner le record comme dans un get
      })
    );
  }

  delete(ticketId: number = 0, lineNumber: number = 0): Observable<any> {
    return this.http.delete<any>(`${apiUrl}services/TicketDetails/${ticketId}/${lineNumber}/`);
  }

  // ODATA : STATE

  odata(state: State): Observable<any> {
    let _state = this.gridService.fixStateFilterDate('ticketDetailDate', state);
    let uri = `${apiUrl}services/TicketDetails?${toODataString(_state)}&$count=true`;
    return this.http.get<TicketDetail>(uri).pipe(
      tap((response: any) => {
        //this.ticketdetailsOdata.next(response);
      })
    );
  }

  // ODATA  : STRING

  odataStr(state: State, oDataString: string): Observable<any> {
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    let uri = `${apiUrl}services/TicketDetails?${oDataFullString}&$count=true`;
    return this.http.get<any>(uri);
  }

  // POPUP EDIT COMMENTAIRE

  public popupEdit(ticketDetail: TicketDetail): any {
    const confirmObservable = new Observable((observer) => {
      // open dialog
      this.dialog = this.dialogService.open({
        content: TicketDetailEditPopupComponent,
        minWidth: 400,
        cssClass: 'si-dialog-edit',
      });
      // set default values
      const dialogData = this.dialog.content.instance as TicketDetailEditPopupComponent;
      dialogData.paramTicketDetail = ticketDetail;
      //dialogData.title = 'module.customer.popup.title';
      //dialogData.button_cancel = 'module.customer.popup.button_cancel';
      // subscribe to response
      this.dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          // cancel
          observer.next(false);
        } else {
          // confirm
          observer.next(result);
        }
      });
    });
    return confirmObservable;
  }
}
