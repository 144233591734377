import { Injectable } from '@angular/core';

import { appUser } from 'src/app/module/security/user/user.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { permissionCodeEnum } from 'src/app/core/permission-code.enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private authService: AuthService) {}

  // SIDENAV

  // afficher le lien USAGER dans le Sidenav
  public canSeeSidenavUser(user?: appUser): boolean {
    return this.TestSimpleRightExistence(permissionCodeEnum.SECURITY__USERS__CAN_LIST_SIMPLE);
  }

  // afficher le lien USAGER dans le Sidenav
  public canSeeSidenavRole(user?: appUser): boolean {
    return this.TestSimpleRightExistence(permissionCodeEnum.SECURITY__USERS__CAN_MANAGE_SECURITY);
  }

  // afficher le lien CUSTOMER dans le Sidenav ET autorise la route dans le guard
  public canSeeSidenavCustomer(user?: appUser): boolean {
    return this.TestSimpleRightExistence(permissionCodeEnum.AR__CUSTOMER__CAN_LIST_SIMPLE);
  }

  // afficher le lien SERVICE CONTRACT dans le Sidenav ET autorise la route dans le guard
  public canSeeSidenavServiceContract(user?: appUser): boolean {
    return this.TestSimpleRightExistence(permissionCodeEnum.AR__SERVICE_CONTRACT__CAN_LIST);
  }

  // afficher le lien LICENCING dans le Sidenav ET autorise la route dans le guard
  public canSeeSidenavLicencing(user?: appUser): boolean {
    var CanListSimple = this.TestSimpleRightExistence(permissionCodeEnum.SOFTMNGR__CUSTLICENCE__CAN_LIST_SIMPLE);
    var CanListFull = this.TestSimpleRightExistence(permissionCodeEnum.SOFTMNGR__CUSTLICENCE__CAN_LIST_FULL);
    var CanListModifyFull = this.TestSimpleRightExistence(permissionCodeEnum.SOFTMNGR__CUSTLICENCE__CAN_MODIFY_FULL);
    return CanListSimple || CanListFull || CanListModifyFull;
  }

  // afficher le lien SOFTWARE dans le Sidenav ET autorise la route dans le guard
  public canSeeSidenavSoftware(user?: appUser): boolean {
    return this.TestSimpleRightExistence(
      permissionCodeEnum.SOFTMNGR__CUSTLICENCE__CAN_LIST_SIMPLE // TEMPORAIRE
      //permissionCodeEnum.PRIVILEGE_SOFTWARE_MODIFY
    );
  }

  // afficher le lien SOFTWARE VERSION dans le Sidenav ET autorise la route dans le guard
  public canSeeSidenavSoftwareVersion(user?: appUser): boolean {
    return this.TestSimpleRightExistence(
      permissionCodeEnum.SOFTMNGR__CUSTLICENCE__CAN_LIST_SIMPLE // TEMPORAIRE
      //permissionCodeEnum.PRIVILEGE_SOFTWARE_VERSION_MODIFY
    );
  }

  /**
   *  afficher le lien SOFTWARE SESSION LOG dans le Sidenav ET autorise la route dans le guard
   */
  public canSeeSidenavSoftwareLog(user?: appUser): boolean {
    return this.TestSimpleRightExistence(permissionCodeEnum.SOFTMNGR__SOFTLOGS__CAN_LIST);
  }

  // afficher le lien AUDIT LOG dans le Sidenav ET autorise la route dans le guard
  public canSeeSidenavAuditLog(user?: appUser): boolean {
    return this.TestSimpleRightExistence(permissionCodeEnum.SYSTEM__SYSTEM__ADMIN);
  }

  // afficher le lien FEUILLE DE TEMPS dans le Sidenav ET autorise la route dans le guard
  public canSeeSidenavFdt(user?: appUser): boolean {
    return this.TestSimpleRightExistence(permissionCodeEnum.SERVICES__TICKETS__CAN_SEE_TICKETS);
  }

  // afficher le lien TICKET dans le Sidenav ET autorise la route dans le guard
  public canSeeSidenavTicket(user?: appUser): boolean {
    return this.TestSimpleRightExistence(permissionCodeEnum.SERVICES__TICKETS__CAN_SEE_TICKETS);
  }

  /*
  // afficher le lien AUDIT LOG dans le Sidenav
  public canSeeSidenavAuditLogs(user?: User): boolean {
    return this.TestSimpleRightExistence(
      permissionCodeEnum.PRIVILEGE_SYSTEM_ADMIN
    );
  }
  */

  //ERP CONNECTORS MODULES

  /***
   * afficher le lien ERP connector company info dans le Sidenav ET autorise la route dans le guard
   * @description //TODO : La permission n'existe pas encore
   */
  public canSeeSidenavErpCompanyConnectorConnexionInfo(user?: appUser): boolean {
    return true;
  }

  /***
   * afficher le lien Task History dans le Sidenav ET autorise la route dans le guard
   * @description //TODO : La permission n'existe pas encore
   */
  canSeeTaskHistory(user?: appUser): boolean {
    return true;
  }

  // USER MODULE

  // permet de modifier les mots de passe
  public canChangePassword(user?: appUser): boolean {
    return this.TestSimpleRightExistence(permissionCodeEnum.SYSTEM__SYSTEM__ADMIN);
  }

  // permet de modifier les droits ssager
  public canEditUserRight(user?: appUser): boolean {
    return this.TestSimpleRightExistence(permissionCodeEnum.SECURITY__USERS__CAN_MANAGE_SECURITY);
  }

  /**
   * Cette fonction retourne true si l'usager courant à la permission (claim) spécifiée
   * @param claimName
   * @returns
   */
  public TestSimpleRightExistence(privilegeToTest: permissionCodeEnum): boolean {
    let TestExist = this.authService.GetCurrentUser().userRightAssignationDTOs?.filter((x) => x.assignedPrivilegeCode == privilegeToTest);

    if (TestExist != null && TestExist.length > 0) return true;
    else return false;
  }
}
