/**Ce modèle permet de stocker et passer de l'information sur ce qu'onv eut afficher ou non, sur ce que l'usager peut voir ou pas,
 *  quand c'est le temps de modifier/créer des tickets hedae4r ou tickets details */
export class TicketEditConfiguration {
  /**Indioque si l'usager peut modifier une partie ou toute l'entete d'un ticket (description, statut) */
  public canEditHeaderInfo: boolean = false;
  /**Indique si on veut afficher l'intégralitée de l'entete du ticket (pourrait être toggle) */
  public mustShowFullHeader: boolean = false;

  public canChangeTechnician: boolean = false;

  /**Date minimale que l'usager peut choisir pour la date de l'entrée de temps/commentaire */
  public minTicketDetailDate: Date = new Date(1900, 1, 1);
  /**Date maximal que l'usager peut choisir pour la date de l'entrée de temps/commentaire */
  public maxTicketDetailDate: Date = new Date(2099, 12, 31);
}
