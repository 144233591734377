import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LocalStorage } from 'src/app/core/local-storage.enum';
import { permissionCodeEnum } from '../permission-code.enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { appUser } from 'src/app/module/security/user/user.model';
import { AuthUserLogin } from 'src/app/core/auth/auth-user-login.model';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //contient les informations de l'usager actuellement connecté
  private _currentAuthUser: appUser = new appUser();
  currentAuthUserBehavior: BehaviorSubject<appUser> = new BehaviorSubject<appUser>(this._currentAuthUser);

  public GetCurrentUser(): appUser {
    return this._currentAuthUser;
  }

  constructor(private http: HttpClient, private router: Router) {}

  login(username: string, password: string): Observable<AuthUserLogin> {
    const myUri = `${apiUrl}security/Auth/Login`;
    var myTask = this.http.post<AuthUserLogin>(myUri, {
      username: username,
      password: password,
    });
    return this.processLogin(myTask);
  }

  loginSSO(token: string) {
    const myUri = `${apiUrl}security/Auth/LoginWithMicrosoftSSO?token=${token}`;
    var myTask = this.http.post<AuthUserLogin>(myUri, {});
    return this.processLogin(myTask);
  }

  /**
   * Cette méthode permet de traiter le retour de l'API de login, et de faire les actions nécessaires.
   **/
  processLogin(myTask: any) {
    myTask = myTask.pipe(
      tap((myLoginReturnInfo: AuthUserLogin) => {
        // decode
        const helper = new JwtHelperService(myLoginReturnInfo.accessToken);

        // set localstorage keys
        localStorage.setItem(LocalStorage.JWT, myLoginReturnInfo.accessToken); // on stocke le JWT dans la mémoire du navigateur
        localStorage.setItem(LocalStorage.JWT_REFRESH_TOKEN, myLoginReturnInfo.refreshToken);
        localStorage.setItem(LocalStorage.JWT_SOURCE_SERVER, environment.apiUrl);

        //on va sérializer les droits dnas un string, pour les stocker dans le localstorage
        let myRights = JSON.stringify(myLoginReturnInfo.userRightAssignationDTOs);
        // set localstorage keys
        localStorage.setItem(LocalStorage.SECURITY_EFFECTIVE_RIGHTS, myRights); // on stocke le JWT dans la mémoire du navigateur

        // clean localstorage keys
        localStorage.removeItem(LocalStorage.USER_FULL_NAME);

        this.hydrate();
        this.router.navigate(['/auth/home']);
        return myLoginReturnInfo;
      }),
      catchError((error: any) => {
        // handle error here
        console.error('An error occurred:', error);
        throw error;
      })
    );
    return myTask;
  }

  logout(redirect: boolean) {
    // remove localstorage keys
    //localStorage.clear();  NON! pas à court terme,  Car ça va, entre autre, détruire les preset de l'usager!

    //on va boucler sur tous les enum de LocalStorage, et les enlever un par un
    for (let myEnum in LocalStorage) {
      myEnum = myEnum.toLowerCase();
      //on ne veut pas supprimer les settings de grid à chaque déconnexion
      if (myEnum == LocalStorage.GRID_ITEM_PER_PAGE) continue;
      if (myEnum == LocalStorage.GRID_STATE_TICKET_LIST) continue;
      localStorage.removeItem(myEnum);
    }

    // reset
    this.resetCurrentUserData(true);
    // redirect
    if (redirect) this.router.navigate(['/auth']);
    // call api logout
    return this.http.post(apiUrl + 'security/Auth/logout', {}).pipe(tap((resData) => {}));
  }

  hydrate(): void {
    // decode token
    const helper = new JwtHelperService();
    var jwt = localStorage.getItem(LocalStorage.JWT) as string;
    this._currentAuthUser = new appUser();

    let myRightsAsString = localStorage.getItem(LocalStorage.SECURITY_EFFECTIVE_RIGHTS);
    if (myRightsAsString != null) {
      this._currentAuthUser.userRightAssignationDTOs = JSON.parse(myRightsAsString);
    }

    try {
      const decodedToken = helper.decodeToken(jwt) as any;

      if (decodedToken) {
        // assign
        this._currentAuthUser.id = decodedToken['UserID'];
        this._currentAuthUser.isCurrentlyLogged = true;

        //************************************
        //Liste des privilèges de mon usager - transférer dans une variable globalement accessible.  le claim devra toujours etre en dictionnaire
        for (const claim in decodedToken) {
          if (claim.startsWith('PRIVILEGE_')) {
            let myResult = decodedToken[claim];
            let myClaim: string[];

            //on s'assure que le claim value est un array de string, sinon on le forcera (.net core ne retourne pas toujours un array)
            if (Array.isArray(myResult)) myClaim = myResult;
            else myClaim = [myResult];

            //on va ajouter le claim dans le dictionnaire, sinon on le concatemne si existe deja
            if (this._currentAuthUser.listPrivilegeByClaims[claim] == null) this._currentAuthUser.listPrivilegeByClaims[claim] = myClaim;
            else this._currentAuthUser.listPrivilegeByClaims[claim] = this._currentAuthUser.listPrivilegeByClaims[claim].concat(myClaim);
          }
        }
        //************************************

        this._currentAuthUser.userName = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/upn'];
        this._currentAuthUser.userFullName = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
        this._currentAuthUser.email = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
      }
    } catch (error) {
      this.logout(true);
    }

    this.notifyAuthUserChange('hydrate');
  }

  /** Cette méthode permet de notifier tout les subscribers que l'usager a changé.
   *
   * @param sourceNotif
   */
  private notifyAuthUserChange(sourceNotif: string) {
    //la partie ici, permet de NOTIFIER tout les subscribers que l'usager a changé
    this.currentAuthUserBehavior.next(this._currentAuthUser);
  }

  /** Cette méthode permet de réinitialiser l'usager en cours.  C'est utile lorsqu'on veut se déconnecter, ou lorsqu'on veut se faire passer pour un autre usager.
   *
   * @param mustNotify
   */
  resetCurrentUserData(mustNotify: boolean = true) {
    this._currentAuthUser = new appUser();
    if (mustNotify) this.notifyAuthUserChange('reset');
  }

  checkIfLoginExpired(): boolean {
    const helper = new JwtHelperService();
    var jwt = localStorage.getItem(LocalStorage.JWT) as string;
    if (helper.isTokenExpired(jwt)) {
      return true;
    }
    return false;
  }

  checkIfSameEnvironment(): boolean {
    return localStorage.getItem(LocalStorage.JWT_SOURCE_SERVER) === environment.apiUrl;
  }

  /**
   * Cette méthode permet de revenir à l'usager original.  le JWT sera changé pour celui de l'usager original.
   */
  public StopImpersonation(): Observable<AuthUserLogin> {
    var jwt = localStorage.getItem(LocalStorage.JWT_IMPERSONATOR) as string;
    localStorage.setItem(LocalStorage.JWT, jwt); // on stocke le JWT dans la mémoire du navigateur

    var securityEffectiveRights = localStorage.getItem(LocalStorage.SECURITY_EFFECTIVE_RIGHTS_IMPERSONATOR) as string;
    localStorage.setItem(LocalStorage.SECURITY_EFFECTIVE_RIGHTS, securityEffectiveRights);
    localStorage.removeItem(LocalStorage.JWT_IMPERSONATOR);
    localStorage.removeItem(LocalStorage.JWT_IMPERSONATOR);

    // hydrate & redirect
    this.hydrate();

    return of();
  }

  /**
   * Cette méthode permet de se faire passer pour un autre usager.  le JWT sera changé pour celui de l'usager à impersonner.
   * @param UserIdToImpersonate
   */
  public ImpersonateUser(UserIdToImpersonate: string) {
    var jwt = localStorage.getItem(LocalStorage.JWT) as string;
    localStorage.setItem(LocalStorage.JWT_IMPERSONATOR, jwt); // on stocke le JWT dans la mémoire du navigateur

    //on transfert les droits effectifs de l'usager courant, dans une variable temporaire
    var securityEffectiveRights = localStorage.getItem(LocalStorage.SECURITY_EFFECTIVE_RIGHTS) as string;
    localStorage.setItem(LocalStorage.SECURITY_EFFECTIVE_RIGHTS_IMPERSONATOR, securityEffectiveRights);

    let strUri = `${apiUrl}security/Auth/ImpersonateUser?UserIdToImpersonate=${UserIdToImpersonate}`;

    return this.http.post<AuthUserLogin>(strUri, {}).pipe(
      tap((resData) => {
        this.resetCurrentUserData();
        // decode
        const helper = new JwtHelperService(resData.accessToken);
        // set localstorage keys
        localStorage.setItem(LocalStorage.JWT, resData.accessToken); // on stocke le JWT dans la mémoire du navigateur
        localStorage.setItem(LocalStorage.JWT_REFRESH_TOKEN, resData.refreshToken);
        localStorage.setItem(LocalStorage.JWT_SOURCE_SERVER, environment.apiUrl);

        // hydrate & redirect
        this.hydrate();
      })
    );
  }

  public isCurrentlyImpersonating(): boolean {
    const jwtImpersonator = localStorage.getItem(LocalStorage.JWT_IMPERSONATOR);
    return jwtImpersonator != null && jwtImpersonator !== '' && jwtImpersonator !== 'JWT_IMPERSONATOR';
  }

  /**  Permet d'ajouter nom/prenom et autre info à l'usager en cours.
   * ATTENTION!  Ne pas écraser des valeurs comme le userId, IsLogged, les claims, etc..   C'est juste pour ajouter des infos supplémentaires
   * @param User
   */
  public addUserNames(User: appUser): void {
    this._currentAuthUser.userName = User.userName;
    this._currentAuthUser.userFullName = User.userFullName;
  }

  /** Cette méthode permet de RAFRAICHIR le AccessToken, grace au REFRESH token
   * on peut ne PAS
   * @param myUserLoginPayload permet de passer un model de login FORCÉE.
   * LAISSER VIDE pour prendre lui par défaut
   * @returns TRUE si ca a marché, FALSE sinon
   */
  public refreshAuthTokens(myUserLoginPayload: AuthUserLogin | undefined = undefined): Observable<AuthUserLogin> {
    //**************************
    //#region STEP1 - Préparation du payload à pousser pour rafraichir le token
    if (myUserLoginPayload == null) {
      //on fetch du LocalStorage
      let accessToken = localStorage.getItem(LocalStorage.JWT) as string;

      let refreshToken = localStorage.getItem(LocalStorage.JWT_REFRESH_TOKEN) as string;

      //on refait le payload
      myUserLoginPayload = {
        accessToken: accessToken,
        refreshToken: refreshToken,
      } as AuthUserLogin;
    }
    //si l'usager à passé un payload,  ca pourrait être pour rafraichir le token d'une session en impersonate (par exemple).
    else {
      //NE PAS IMPLÉMENTER POUR L'INSTANT
    }
    //#endregion
    //**************************

    let strUri = apiUrl + 'security/Auth/RefreshToken';

    //**************************
    //#region STEP2 - Appel à l'API et traitement du retour
    return this.http.post<AuthUserLogin>(strUri, myUserLoginPayload).pipe(
      tap((resData) => {
        // decode
        const helper = new JwtHelperService(resData.accessToken);

        // set localstorage keys
        localStorage.setItem(LocalStorage.JWT, resData.accessToken); // on stocke le JWT dans la mémoire du navigateur
        localStorage.setItem(LocalStorage.JWT_REFRESH_TOKEN, resData.refreshToken);

        // hydrate & redirect
        this.hydrate();
      })
    );
    //#endregion
    //**************************
  }
}
