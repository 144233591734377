/**
 * Liste des clé Window.localStorage utilisées dans l'application.
 */
export enum LocalStorage {
  LANG = 'lang',
  JWT = 'jwt_content',
  JWT_IMPERSONATOR = 'jwt_impersonator',
  JWT_SOURCE_SERVER = 'jwt_source_server',
  JWT_REFRESH_TOKEN = 'jwt_refresh_token', //Ce token permet de demander un refresh du JWT [le access token]

  SIDENAV_EXPANDED = 'sidenav_expanded',
  GRID_ITEM_PER_PAGE = 'grid_item_per_page',
  GRID_STATE_TICKET_LIST = 'grid_state_ticket_list',

  SECURITY_EFFECTIVE_RIGHTS = 'security_effective_rights', //Liste des droits EFFECTIFS de l'usaer, au moment de sa connexion ou de refresh de droits..
  SECURITY_EFFECTIVE_RIGHTS_IMPERSONATOR = 'security_effective_rights_impersonator', //Liste des droits EFFECTIFS de l'usaer, au moment de sa connexion ou de refresh de droits..

  LOGIN_REMEMBER_ME = 'login_remember_me',
  LOGIN_LAST_CODE = 'login_last_code',
  LOGIN_PREFERED_METHOD = 'login_prefered_method',
  USER_FULL_NAME = 'user_full_name',

  CACHE_CUSTOMER = 'cache_customer',
  CACHE_INVOICING_CATEGORY = 'cache_invoicing_category',
  CACHE_SOFTWARE = 'cache_software',
  CACHE_USER = 'cache_user',
  CACHE_TICKET = 'cache_ticket',
  CACHE_TICKET_TYPE = 'cache_ticket_type',
  CACHE_TICKET_STATUS = 'cache_ticket_status',
  CACHE_LIST_TECHNICIAN = 'cache_list_technician',
  CACHE_DEPARTMENT = 'cache_department',
  CACHE_ACTIVITY_CODE = 'cache_activity_code',
  CACHE_LICENCING = 'cache_licencing',
  USERPROFILE_TIMESHEET_LAST_SC_USER = 'userprofile_timesheet_last_sc_user',
}
