import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable, of } from 'rxjs';
import { mergeMap, tap, map, switchMap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State, toODataString } from '@progress/kendo-data-query';
import { UtilService } from 'src/app/shared/util.service';
import { InvoicingCategory } from '../service/invoicing-category/invoicing-category.model';
import { LocalStorage } from 'src/app/core/local-storage.enum';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class DynamicEntityService {
  private utilService = inject(UtilService);
  constructor(private http: HttpClient) {}

  /*
  liste des valeurs d'une entité dynamique

[
    "Appel",
    "AppelAEffectuer",
    "AppelClient",
    "AppelStatut",
    "AppelSuivi",
    "AppelTemp",
    "AppelTempsCommentaire",
    "Cappel",
    "CappelAEffectuer",
    "CappelClient",
    "CappelSuivi",
    "CappelTemp",
    "CappelTempsCommentaire",
    "CategorieAppel",
    "ClientsAcomba",
    "Config",
    "Contrat",
    "ContratCommentaire",
    "ContratItem",
    "ContratType",
    "Priorite",
    "SpeciauxClientsItem",
    "TypesAppel",
    "Usager"
]

  */

  list(entity: string): Observable<any[]> {
    let uri = `${apiUrl}servicentre/DynamicEntity/${entity}`;
    return this.http.get<any[]>(uri).pipe(
      mergeMap((response: any) => {
        return of(response);
      })
    );
  }

  /*
  read(id: number): Observable<any> {
    return this.http.get<any>(apiUrl + 'servicentre/DynamicEntity/' + id, {}).pipe(
      tap((response: any) => {
        return of(response);
      })
    );
  }

  // ODATA : STATE

  odata(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${apiUrl}servicentre/DynamicEntity?${toODataString(_state)}&$count=true`;

    return this.http.get<any>(uri).pipe(
      tap((response: any) => {
        return of(response);
      })
    );
  }
*/
  // ODATA  : STRING

  odataStr(state: State, oDataString: string): Observable<any> {
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    let uri = `${apiUrl}servicentre/DynamicEntity/${oDataFullString}&$count=true`;
    return this.http.get<any>(uri);
  }

  /**
   * cette fonction retourne la liste de des invoicings categories mis en cache dans le localStorage.
   * si  la cache n'A pas été rafraichie depuis x minutes, on la rafraichie.
   * on fetch les données de l'API de facon recursive pour obtenir tous les records.
   * @param forceFetch
   * @returns la liste de tous les invoicing category
   */
  getListOfInvoicingCategory(forceFetch: boolean = false): Observable<InvoicingCategory[]> {
    let fetch = false;
    // read localStorage
    let ls = localStorage.getItem(LocalStorage.CACHE_INVOICING_CATEGORY);
    if (forceFetch) {
      fetch = true;
    } else if (ls != null && ls.length > 0) {
      let data = JSON.parse(ls!);
      let now = Date.now();
      // force to refresh every X seconds
      if (now - data.lastUpdate > 5 * 60 * 1000) {
        fetch = true;
      }
    } else {
      // empty localstorage
      fetch = true;
    }
    // fetch or return cache data
    if (fetch) {
      let myURI = apiUrl + 'Servicentre/DynamicEntity/CategorieAppel?$select=type,description';
      return this.http.get<InvoicingCategory[]>(myURI).pipe(
        mergeMap((response: any) => {
          let allCategories: InvoicingCategory[] = [];

          //on va mapper les données de l'API dans notre model
          //ce n'est pas ODATAm, donc lit REPONSE directement
          response.forEach((element: any) => {
            let myCategory = new InvoicingCategory();
            myCategory.categoryCode = element.Type;
            myCategory.longDescription = element.Description;

            //on ajoute la categorie a notre liste
            allCategories.push(myCategory);
          });

          //on va pousser l'objet de cache (avec la date de mise a jour)
          let newCache = {
            lastUpdate: Date.now(),
            data: allCategories,
          };
          localStorage.setItem(LocalStorage.CACHE_INVOICING_CATEGORY, JSON.stringify(newCache));
          return of(allCategories);
        })
      );
    } else {
      let data = JSON.parse(ls!);
      return of(data.data);
    }
  }
}
