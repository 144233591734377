/**
 * Cette classe représente une LIGNE d'un d'inlet.  c'est ici qu'on y retrouve le TEMPS, les commentaires, etc..
 *
 */
export class InvoicingCategory {
  /**
   * Numéro du ticket parent
   */
  public categoryCode?: string = '';

  public longDescription?: string = '';
}
