import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter, inject, SimpleChanges, input } from '@angular/core';
import * as svgIcons from '@progress/kendo-svg-icons';
import { catchError, Observable, take, takeUntil, Subject, tap, switchMap, forkJoin } from 'rxjs';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';

import { TicketDetailService } from '../ticket-detail.service';
import { UtilService } from 'src/app/shared/util.service';
import { TicketDetail } from '../ticket-detail.model';
import { TicketHeader } from '../../ticket-header/ticket-header.model';
import { InvoicingCategory } from '../../../invoicing-category/invoicing-category.model';
import { TicketEditConfiguration } from '../../../shared/ticket-edit-configuration.model';
import { Technician } from '../../../shared/technician.model';
import { ActivityCode } from '../../../shared/activity-code.model';
import { ServiceSharedService } from '../../../shared/service-shared-services';
import { DynamicEntityService } from 'src/app/module/servicentre/dynamic-entity.service';
import { FileManagerService } from 'src/app/shared/file-manager.service';

@Component({
  selector: 'app-ticket-detail-edit-popup',
  templateUrl: './ticket-detail-edit-popup.component.html',
  styleUrls: ['./ticket-detail-edit-popup.component.scss'],
})
export class TicketDetailEditPopupComponent extends DialogContentBase implements OnInit {
  private ticketDetailService = inject(TicketDetailService);
  private utilService = inject(UtilService);
  private serviceSharedService = inject(ServiceSharedService);
  private dynamicEntityService = inject(DynamicEntityService);
  private fileManagerService = inject(FileManagerService);

  private unsubNotifier = new Subject<void>();

  @Input() public ticketEditConfiguration: TicketEditConfiguration = new TicketEditConfiguration();

  @Input() isLoading = false;
  @Input() isNewTicketDetailMode = false; //si on est en mode ajout.
  @Input() ticketHeader!: TicketHeader;
  @Input() ticketDetail!: TicketDetail;
  @Input() paramTicketDetail!: TicketDetail;
  @Input() activityCodeList: ActivityCode[] = [];
  @Input() invoicingCategoryList: InvoicingCategory[] = [];
  @Input() technicianList: Technician[] = [];

  @Output() refreshFdtList = new EventEmitter();
  @Output() refreshEditList = new EventEmitter();
  @Output() refreshTicketHeader = new EventEmitter();
  @Output() isLoadingChange = new EventEmitter<boolean>();
  @Output() updateEditFormDirtyState = new EventEmitter<boolean>();

  public allIcons = svgIcons;
  private ticketDetailBackup: TicketDetail = new TicketDetail();
  private ticketDetailDateBackup: Date | undefined = new Date();

  public activityCodeFilterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  public technicianFilterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  public invoiceCategoryFilterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  constructor(public override dialog: DialogRef) {
    super(dialog);
    //this.ticketDetail = new TicketDetail();
    // fetch data
    this.isLoading = true;
    forkJoin({
      activityCodeList: this.serviceSharedService.getListOfActivityCode(),
      invoicingCategoryList: this.dynamicEntityService.getListOfInvoicingCategory(false),
      technicianList: this.serviceSharedService.getListOfTechnician(),
    }).subscribe((response: any) => {
      this.isLoading = false;
      this.activityCodeList = response.activityCodeList;
      this.invoicingCategoryList = response.invoicingCategoryList;
      this.technicianList = response.technicianList;
    });
  }

  /**On note la valeur des heures travaillées précédentes, pour "cloner" les hrs facturables quand c'est identiques.. */
  private previousRealWorkedHours: number = 0;
  private previousRealTravelledHours: number = 0;

  ngOnInit(): void {
    // init data
    this.initData();
  }

  initData() {
    // cloner l'object param
    this.ticketDetail = Object.assign({}, this.paramTicketDetail);
    // backup object pour change detection
    this.ticketDetailBackup = Object.assign({}, this.paramTicketDetail);
    // set previous variables pour champs heures travaillées et déplacements
    this.previousRealWorkedHours = this.ticketDetail.realWorkedHours ?? 0;
    this.previousRealTravelledHours = this.ticketDetail.realTravelledHours ?? 0;
    // set mode
    this.isNewTicketDetailMode = this.ticketDetail.lineNumber == undefined || this.ticketDetail.lineNumber == null;

    // format date value
    //console.log('FORMAT DATE ?', typeof this.ticketDetail.ticketDetailDate, this.ticketDetail.ticketDetailDate);
    /*
    if (typeof this.ticketDetail.ticketDetailDate == 'string') {
      let waat = this.ticketDetail.ticketDetailDate ?? ''; // doit etre format yyyy-mm-ddTHH:MM:SS
      let date: Date = this.utilService.formatStringToDate(waat);
      this.ticketDetail.ticketDetailDate = date;
    } else if (typeof this.ticketDetail.ticketDetailDate == 'object') {
      this.ticketDetail.ticketDetailDate = this.utilService.formatDateToString(this.ticketDetail.ticketDetailDate);
    }
    
*/

    /*
    if (this.ticketDetail?.ticketId) {
      // UPDATE

      //on va copier TicketDetail aucomplet dans this.ticketDetailBackup
      // backup object
      this.ticketDetail = Object.assign({}, this.paramTicketDetail);
      // previous values
      this.previousRealWorkedHours = this.ticketDetail.realWorkedHours ?? 0;
      this.previousRealTravelledHours = this.ticketDetail.realTravelledHours ?? 0;
      //on va trouver le technician qui fite :
      //let myTech = this.technicianList.find((x) => x.TechnicianCode == this.ticketDetail.scTechnicianCode);
      // format date value
      let waat = this.ticketDetail.ticketDetailDate?.toString() ?? '';
      let date: Date = this.utilService.formatStringToDate(waat);
      this.ticketDetail.ticketDetailDate = date;
    } else {
      // CREATE

      // set default values
      let newTicketDetail = new TicketDetail();
      newTicketDetail.scInvoicingCategoryCode = this.ticketHeader.scInvoicingCategoryCode;
      newTicketDetail.ticketId = this.ticketHeader.ticketId;
      //newTicketDetail.scTechnicianCode = this.scTechnicianCode;
      if (newTicketDetail.scTechnicianCode == '' || newTicketDetail.scTechnicianCode == null) {
        newTicketDetail.scTechnicianCode = this.ticketHeader.sC_Ticket_CreatedBy_UserCode;
      }

      //this.gererDateDebutFin();

      let DateToUse = new Date().toISOString();
      //si la date de fin est plus basse que getFullYear, alors c'est soit un range, soit une date unique...auxquel cas, on veut prednre cette date
      if (this.ticketEditConfiguration.maxTicketDetailDate.getFullYear() < 2099) {
        DateToUse = this.ticketEditConfiguration.maxTicketDetailDate.toISOString();
      }

      if (DateToUse == '' || DateToUse == null) {
        DateToUse = new Date().toISOString();
      }

      let date: Date = this.utilService.formatStringToDate(DateToUse);
      newTicketDetail.ticketDetailDate = date;
    }
    */
  }

  ngOnDestroy() {
    this.unsubNotifier.next();
    this.unsubNotifier.unsubscribe();
  }

  /**
   * Microfonction qui indique si les heures travaillées sont égales aux heures facturables...permettra de griser les heures facturables...
   */
  public isHoursEqual(): boolean {
    return this.ticketDetail.billableWorkedHours == this.ticketDetail.realWorkedHours;
  }

  /**
   * Microfonction qui indique si les déplacements réel sont égales aux déplacements facturables...permettra de griser les déplacements facturables...
   */
  public isDeplacementEqual(): boolean {
    return this.ticketDetail.billableTravelledHours == this.ticketDetail.realTravelledHours;
  }

  /*
   * Sauvegarde les modifications,
   */
  onSubmit() {
    // preparation de la liste et update des images avec code temporaires

    let imageList: any = [];
    let result: any;
    // internalComment
    result = this.fileManagerService.prepareFieldWithImagesToUpload(this.ticketDetail.internalComment, imageList);
    let internalComment = result.texte;
    imageList = result.imageList;
    // publicComment
    result = this.fileManagerService.prepareFieldWithImagesToUpload(this.ticketDetail.publicComment, imageList);
    let publicComment = result.texte;
    imageList = result.imageList;

    // upload de tous les images

    this.isLoading = true;

    this.fileManagerService.uploadAllImages('ticket', this.ticketDetail.ticketId, imageList).then((response) => {
      // mettre à jour les descriptions avec les urls des images

      imageList = response;
      this.ticketDetail.internalComment = this.fileManagerService.replaceTemporaryCodeByCdnUrl(internalComment, imageList);
      this.ticketDetail.publicComment = this.fileManagerService.replaceTemporaryCodeByCdnUrl(publicComment, imageList);

      // ici on peut create ou update.

      let postData: TicketDetail = this.preparePostData();

      this.isLoading = true;
      this.isLoadingChange.emit(true);
      if (this.isNewTicketDetailMode) {
        // CREATE
        this.ticketDetailService
          .create(postData)
          .pipe(
            catchError((error: any) => {
              this.isLoading = false;
              this.isLoadingChange.emit(false);
              throw error;
            })
          )
          .pipe(take(1))
          .subscribe((response) => {
            this.isLoading = false;
            this.dialog.close(response);
          });
      } else {
        // UPDATE
        this.ticketDetailService
          .update(postData)
          .pipe(
            catchError((error: any) => {
              this.isLoading = false;
              this.isLoadingChange.emit(false);
              throw error;
            })
          )
          .pipe(take(1))
          .subscribe((response) => {
            this.isLoading = false;
            this.dialog.close(response);
          });
      }
    });
  }

  /**
   * Cette fonction sert a uniformiser le data envoyé lors d'un create ou un update
   * et permet d'exclure tous les champs insignifiants du payload
   * @returns le data pour un create ou un update de la table usager
   */
  preparePostData(): TicketDetail {
    //on va nettoyer le string qui doit reseter un beau TXT plain pour servicentre
    let myCommentToCleanse = this.utilService.removeSpecialCharacters(this.ticketDetail.servicentreComment) ?? '';
    //on doit s'assurer de convertir les retours de chariots SIMPLE  (\n) en \r\n pour servicentre
    myCommentToCleanse = myCommentToCleanse.replace(/(?<!\r)\n/g, '\r\n');
    this.ticketDetail.servicentreComment = myCommentToCleanse;

    return this.ticketDetail;
  }

  public cancel() {
    this.dialog.close(false);
    /*
    // NOTE: il faudrait utiliser une form pour détecter le dirty state au lieu de compare touuuus les champs
    if (
      (this.isNewTicketDetailMode && (this.ticketDetail.servicentreComment || this.ticketDetail.realWorkedHours)) ||
      (!this.isNewTicketDetailMode &&
        (this.ticketDetail.servicentreComment != this.ticketDetailBackup.servicentreComment ||
          this.ticketDetail.realWorkedHours != this.ticketDetailBackup.realWorkedHours))
    ) {
      this.utilService
        .confirm(
          'shared.edit.popup_form_changed.title',
          'shared.edit.popup_form_changed.message',
          'shared.edit.popup_form_changed.yes',
          'shared.edit.popup_form_changed.no'
        )
        .subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.dialog.close(false);
          }
        });
    } else {
      this.dialog.close(false);
    }
      */
  }

  /*
   * Affiche un popup de confirmation avant de supprimer un commentaire
   */
  public delete() {
    this.utilService
      .confirm(
        'module.ticket-detail.edit.popup_delete_comment.title',
        'module.ticket-detail.edit.popup_delete_comment.message',
        'module.ticket-detail.edit.popup_delete_comment.yes',
        'module.ticket-detail.edit.popup_delete_comment.no'
      )
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.isLoadingChange.emit(true);
          this.ticketDetailService.delete(this.ticketDetail.ticketId, this.ticketDetail.lineNumber).subscribe(() => {
            this.dialog.close(this.ticketDetail);
            this.isLoadingChange.emit(false);
          });
        }
      });
  }

  // WORKED HOURS

  /*
   * Ajuste la valeur des heures pour qu'elle soit un zero au lieu de vide ou null
   */
  onBlurBillableWorkedHours() {
    if (!this.ticketDetail.billableWorkedHours) {
      this.ticketDetail.billableWorkedHours = 0;
    }
    this.ticketDetail.billableWorkedHours = this.utilService.roundToNextQuarterDecimal(this.ticketDetail.billableWorkedHours);
  }

  onBlurRealWorkedHours() {
    if (!this.ticketDetail.realWorkedHours) {
      this.ticketDetail.realWorkedHours = 0;
    }

    this.ticketDetail.realWorkedHours = this.utilService.roundToNextQuarterDecimal(this.ticketDetail.realWorkedHours);

    if (this.previousRealWorkedHours == this.ticketDetail.billableWorkedHours) {
      this.ticketDetail.billableWorkedHours = this.ticketDetail.realWorkedHours;
    }
    this.previousRealWorkedHours = this.ticketDetail.realWorkedHours;
  }

  // DEPLACEMENT HOURS

  /*
   * Ajuste la valeur des heures pour qu'elle soit un zero au lieu de vide ou null
   */
  onBlurBillableTraveledHours() {
    if (!this.ticketDetail.billableTravelledHours) {
      this.ticketDetail.billableTravelledHours = 0;
    }
    this.ticketDetail.billableTravelledHours = this.utilService.roundToNextQuarterDecimal(this.ticketDetail.billableTravelledHours);
  }

  onBlurRealTraveledHours() {
    if (!this.ticketDetail.realTravelledHours) {
      this.ticketDetail.realTravelledHours = 0;
    }

    this.ticketDetail.realTravelledHours = this.utilService.roundToNextQuarterDecimal(this.ticketDetail.realTravelledHours);

    if (this.previousRealTravelledHours == this.ticketDetail.billableTravelledHours) {
      this.ticketDetail.billableTravelledHours = this.ticketDetail.realTravelledHours;
    }
    this.previousRealTravelledHours = this.ticketDetail.realTravelledHours;
  }

  onTechnicianDropdownOpen(event: any) {
    if (!this.ticketEditConfiguration.canChangeTechnician) {
      event.preventDefault();
      this.utilService.alert(
        'Changer le technicien',
        "Vous ne pouvez pas changer le technicien assigné à cette ligne de temps.  Vous êtes surement en mode Feuille de temps, d'où vous ne pouvez changer l'usager."
      );
    }
  }

  /*
   * Cette fonction sauvegarde la date de la ligne de temps en backup lorsqu'on focus dessus.
   */
  onDateFocus(event: any) {
    this.ticketDetailDateBackup = this.ticketDetail.ticketDetailDate;
  }

  /*
   * Cette fonction est appelée lorsqu'on change la date de la ligne de temps.
   * Un popup de confirmation est affiché si la date est en dehors du filtre de la plage de dates .
   */
  onDateChanged() {
    let date = this.ticketDetail.ticketDetailDate;
    // validate that the date is within the range
    if (
      date &&
      (date.getTime() < this.ticketEditConfiguration.minTicketDetailDate.getTime() ||
        date.getTime() > this.ticketEditConfiguration.maxTicketDetailDate.getTime())
    ) {
      // afficher un popup de confirmation
      this.utilService
        .confirm(
          'module.ticket-detail.edit.popup_date_out_of_range.title',
          'module.ticket-detail.edit.popup_date_out_of_range.message',
          'module.ticket-detail.edit.popup_date_out_of_range.yes',
          'module.ticket-detail.edit.popup_date_out_of_range.no'
        )
        .subscribe((confirmed: boolean) => {
          if (!confirmed) this.ticketDetail.ticketDetailDate = this.ticketDetailDateBackup;
        });
    }
  }

  /*
   * Copie le commentaire dans le clip board
   */
  copyComment() {
    this.utilService.copyToClipboard(this.ticketDetail.servicentreComment);
  }

  /*
   * Cette fonction permet de coller une image dans un éditeur de texte kendo
   */
  onPaste(event: any) {
    // On valide si c'est un paste d'image si oui on valide la grosseur et on annluse le paste event au besoin
    this.fileManagerService.pasteEventImageHandler(event);
  }
}
