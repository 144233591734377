import { Pipe, PipeTransform } from '@angular/core';
/**
 * Retourne le nom du client
 */
@Pipe({
  name: 'getCustomerName',
})
export class CustomerNamePipe implements PipeTransform {
  transform(noClient: any, customerList: any): string {
    // chercher le nom si la liste de client n'est pas vide
    if (customerList) {
      let name = customerList
        .filter((c: any) => c.noClient == noClient)
        .map((c: any) => {
          return c.nomClient;
        });

      if (name && name.length > 0) {
        if (name[0].indexOf(noClient) == -1) {
          // si le nom ne contient pas déjà le nom du client, on retourne noClient - name
          return noClient + ' - ' + name[0];
        } else {
          // sinon on retourne le nom
          return name[0];
        }
      }
    }
    // on retourne le noClient si on ne trouve pas le nom
    return noClient;
  }
}
