import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable, of } from 'rxjs';
import { mergeMap, tap, map, switchMap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State, toODataString } from '@progress/kendo-data-query';

import { TicketType } from './ticket-type.model';
import { UtilService } from 'src/app/shared/util.service';
import { LocalStorage } from 'src/app/core/local-storage.enum';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class TicketTypeService {
  private tickettypes: ReplaySubject<TicketType[]> = new ReplaySubject<TicketType[]>(1);
  public tickettype: ReplaySubject<TicketType> = new ReplaySubject<TicketType>(1);
  public tickettypesOdata: ReplaySubject<any> = new ReplaySubject<any>(1);
  private ticketTypeList: TicketType[] = []; // liste des tickettypes dans la cache

  constructor(private http: HttpClient, private utilService: UtilService) {}

  list(): Observable<TicketType[]> {
    let uri = `${apiUrl}services/TicketType/GetListRaw`;
    return this.http.get<TicketType[]>(uri).pipe(
      mergeMap((response: any) => {
        let value = response.value;
        this.tickettypes.next(value);
        return of(value);
      })
    );
  }

  update(ticketType: TicketType): Observable<any> {
    return this.http.put<any>(apiUrl + 'services/TicketType/' + ticketType.ticketTypeId, ticketType).pipe(
      tap((response: any) => {
        this.tickettype.next(response);
        return of(response);
      })
    );
  }

  // CACHE

  /**
   * cette fonction retourne tous les records mis en cache dans le localStorage.
   * si la cache n'a pas été rafraichie depuis 20 secondes, on la rafraichi.
   * on fetch les données de l'API de facon recursive pour obtenir tous les records.
   * @param forceFetch
   * @returns la liste de tous les records
   */

  listCache(forceFetch?: boolean): Observable<TicketType[]> {
    let fetch = false;
    // read localStorage
    let ls = localStorage.getItem(LocalStorage.CACHE_TICKET_TYPE);
    if (forceFetch) {
      fetch = true;
    } else if (ls != null && ls.length > 0) {
      let data = JSON.parse(ls!);
      let now = Date.now();
      // force to refresh every X seconds
      if (now - data.lastUpdate > 20000) {
        fetch = true;
      }
    } else {
      // empty localstorage
      fetch = true;
    }
    // fetch or return cache data
    if (fetch) {
      // reset cache et start fetching
      localStorage.removeItem(LocalStorage.CACHE_TICKET_TYPE);
      return this.fetchOData(apiUrl + 'services/TicketType/GetListRaw?$select=ticketTypeId,servicentreTextualCode,nameFr');
    } else {
      let data = JSON.parse(ls!);
      // update service variable
      this.ticketTypeList = data.data;
      return of(data.data);
    }
  }

  /*
   * cette fonction fetch les données de l'API de facon recursive pour obtenir tous les records.
   */
  private fetchOData(url: string): Observable<TicketType[]> {
    let uri = `${url}`;
    return this.http.get<any>(uri).pipe(
      switchMap((response) => {
        const data = response as TicketType[];
        // fetch actual cache & update it
        let existingCache = JSON.parse(localStorage.getItem(LocalStorage.CACHE_TICKET_TYPE)!) || { lastUpdate: 0, data: [] };
        existingCache.data = existingCache.data.concat(data);
        existingCache.lastUpdate = Date.now();
        localStorage.setItem(LocalStorage.CACHE_TICKET_TYPE, JSON.stringify(existingCache));
        // update service variable
        this.ticketTypeList = existingCache.data;
        // fetch next page if needed OR return data
        if (response['@odata.nextLink']) {
          // ATTENTION!!! ce endpoint n'est pas oDATA alors cette condition ne s'appliquera jamais
          return this.fetchOData(response['@odata.nextLink']).pipe(map((nextData) => data.concat(nextData)));
        } else {
          return of(data);
        }
      }),
      catchError((error) => {
        console.error('Error fetching data:', error);
        return of([]);
      })
    );
  }

  // ODATA : STATE

  odata(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${apiUrl}services/TicketType?${toODataString(_state)}&$count=true`;

    return this.http.get<TicketType>(uri).pipe(
      tap((response: any) => {
        this.tickettypesOdata.next(response);
      })
    );
  }

  // ODATA  : STRING

  odataStr(state: State, oDataString: string): Observable<any> {
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    let uri = `${apiUrl}services/TicketType?${oDataFullString}&$count=true`;
    return this.http.get<any>(uri);
  }
}
