import { UserRight } from './user-right/user-right.model';

export class appUser {
  /**UserId dans le backend (dans l'API).  c'est un gros string GUID (ex: 6f6e2b7e-7b1e-4b1e-9b1e-4b1e9b1e9b1e) */
  public id: string = '';

  /** Email de l'usager connecté  */
  public email: string = '';

  /**c'est un peu le "code de l'usager".  DAns solution soutien, c'est le courriel de l'usager. */
  public userName: string = '';

  /**Indique si l'usager est actuellement connecté ou non */
  isCurrentlyLogged: boolean = false;

  /** TRÈS IMPORTANT!  Il s'agit ici de l'ensemble des droits de l'usager connecté.  C'est un dictionnaire de clé/valeur. */
  listPrivilegeByClaims: Record<string, string[]> = {};

  /** liste des droits effectifs de l'usager */
  userRightAssignationDTOs?: UserRight[] = [];

  public userFullName: string = ''; //provient du JWT...

  //Nom et prenom : surtout quand on va CRÉER un usager...va pousser ces 2 infos vers l'API...
  public userFirstName: string = '';
  public userLastName: string = '';

  public generalInternalComment: string = '';
  public preferedLanguage: string = '';
  public isEnabled: boolean = false;
  public newInputPassword: string = '';

  constructor() {}
}
