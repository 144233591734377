<div class="si-edit-container">
  <!-- LOADER -->
  <app-loader *ngIf="isLoading"></app-loader>
  <!-- FORM -->
  <form class="si-edit-form" #editform="ngForm" (ngSubmit)="onSubmit(editform)">
    <!-- HEADER  -->
    <div class="si-edit-header">
      <!-- TITLE  -->

      <span class="si-edit-title">
        {{ "module.fdt.edit.title_update" | translate }}
      </span>
      <!-- CLOSE BTN  -->
      <button kendoButton fillMode="flat" themeColor="secondary" [svgIcon]="allIcons.xIcon" type="button" (click)="fermer()"></button>
    </div>
    <!-- CONTENT -->
    <div class="si-edit-content">
      <div class="si-edit-content-button"></div>

      <!-- FIELDSET -->

      <fieldset class="si-edit-fieldset-row">
        <!-- ID -->

        <div class="si-formfield" style="flex: 0 0 100px">
          <kendo-label [for]="ticketId" [text]="'module.ticket.model.ticketId' | translate"></kendo-label>
          <input kendoTextBox name="ticketId" [(ngModel)]="ticketHeader.ticketId" #ticketId readonly class="si-input-readonly" />
        </div>
        <!-- CLIENT -->

        <div class="si-formfield">
          <kendo-label [for]="customerNumber" [text]="'module.ticket.model.customer' | translate"></kendo-label>
          <input kendoTextBox name="customerNumber" [value]="customerName" #customerNumber readonly class="si-input-readonly" />
        </div>

        <!-- STATUT -->
        <div class="si-formfield" style="flex: 0 0 100px">
          <kendo-label [for]="scTicketStatusCode" [text]="'module.ticket.model.scTicketStatusCode' | translate"></kendo-label>
          <input kendoTextBox name="scTicketStatusCode" [(ngModel)]="ticketHeader.scTicketStatusCode" #scTicketStatusCode readonly class="si-input-readonly" />
        </div>

        <!-- isClosed -->
        <div class="si-formfield" style="flex: 0 0 90px">
          <kendo-label [for]="isClosed" [text]="'module.ticket.model.isClosedOrOpen' | translate"></kendo-label>
          <input
            kendoTextBox
            name="isClosed"
            [ngClass]="ticketHeader.isClosed ? 'input-etat-ticket-closed' : 'input-etat-ticket-open'"
            [value]="closedStatusDisplay"
            #isClosed
            readonly
            class="si-input-readonly"
          />
        </div>
      </fieldset>

      <fieldset class="si-edit-fieldset">
        <!-- TITLE -->

        <div class="si-formfield">
          <kendo-label [for]="scTicketShortTitle" [text]="'module.ticket.model.scTicketShortTitle' | translate" class="si-label-no-margin"></kendo-label>
          <input
            kendoTextBox
            name="scTicketShortTitle"
            [value]="ticketHeader.scTicketShortTitle"
            #scTicketShortTitle
            readonly
            class="si-input-readonly si-bt-title"
          />
        </div>
      </fieldset>

      <fieldset class="si-edit-fieldset">
        <!-- COMMENTAIRE -->
        <kendo-expansionpanel [title]="'module.fdt.edit.label_ticket_detail' | translate">
          <div class="si-ticket-header-servicentre-comment-container">
            <textarea kendoTextBox [value]="ticketHeader.scGlobalComment" readonly class="si-ticket-header-servicentre-comment si-input-readonly"></textarea>
          </div>
        </kendo-expansionpanel>
      </fieldset>
      <!-- FILTRE COURANT -->

      <div class="si-detail-filter-container">
        <div class="si-detail-filter-label">{{ "module.fdt.edit.label_active_filter" | translate }} :</div>
        <div *ngIf="scTechnicianCode">
          {{ "module.fdt.edit.label_filter_employee" | translate }} : <b>{{ scTechnicianCode }}</b
          >,
        </div>
        <div *ngIf="ticketDetailDate">
          {{ "module.fdt.edit.label_filter_date" | translate }} : <b>{{ ticketDetailDate }}</b>
        </div>
        <div *ngIf="ticketDetailDateStart">
          {{ "module.fdt.edit.label_filter_date_from" | translate }} <b>{{ ticketDetailDateStart }}</b> {{ "module.fdt.edit.label_filter_date_to" | translate }}
        </div>
        <div *ngIf="ticketDetailDateEnd">
          <b>{{ ticketDetailDateEnd }}</b>
        </div>

        <!-- NAaaah, ça pas vraiment de sens...on va plutôt faire ouvrir le FULL ticket..
        <div *ngIf="showAllCommentsMode"><b>Tous les commentaires</b></div>
        <button *ngIf="!showAllCommentsMode" kendoButton themeColor="secondary" (click)="showAllComments()" type="button" class="si-detail-filter-button-all">
          Afficher tous les commentaires
        </button>
        !-->
      </div>

      <!-- DETAILS -->

      <div class="si-detail-container">
        <div class="si-detail-title">
          <div class="si-detail-add-comment">
            {{ "module.ticket.edit.label_comment" | translate }}
            <!-- BOUTON AJOUTER COMMENTAIRE -->
            @if(!ticketHeader.isClosed) {
            <button kendoButton [svgIcon]="allIcons.plusIcon" themeColor="primary" (click)="addComment()" type="button"></button>
            }
          </div>
          <div class="si-detail-total-hours">
            <div>
              Total Hrs: <b>{{ totalHours }}</b>
            </div>
          </div>
        </div>

        <!-- LISTE DES COMMENTAIRE -->

        @for (myTicketDetailData of ticketDetailList; track myTicketDetailData.lineNumber) {

        <app-ticket-detail-view
          [ticketDetail]="myTicketDetailData"
          [ticketHeader]="ticketHeader"
          [ticketEditConfiguration]="ticketEditConfiguration"
          [isNewTicketDetailMode]="false"
          [isLoading]="isLoading"
          [activityCodeList]="activityCodeList"
          [technicianList]="technicianList"
          [invoicingCategoryList]="invoicingCategoryList"
          (refreshFdtList)="refreshFdtListComponent()"
          (refreshTicketHeader)="refreshTicketHeader()"
          (isLoadingChange)="isLoadingChange($event)"
          (updateEditFormDirtyState)="updateEditFormDirtyState($event)"
          (removeCommentaire)="removeCommentaire($event)"
          (refreshTicketDetailList)="refreshTicketDetailList()"
        ></app-ticket-detail-view>

        }
      </div>
    </div>
  </form>
</div>
