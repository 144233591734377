import { NgModule } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IconModule } from '@progress/kendo-angular-icons';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { MenusModule } from '@progress/kendo-angular-menu';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PopoverModule } from '@progress/kendo-angular-tooltip';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { EditorModule } from '@progress/kendo-angular-editor';

import '@progress/kendo-angular-intl/locales/fr-CA/all';

@NgModule({
  imports: [
    ButtonsModule,
    DateInputsModule,
    DialogsModule,
    WindowModule,
    DropDownsModule,
    GridModule,
    IconModule,
    SVGIconModule,
    IndicatorsModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    MenusModule,
    NotificationModule,
    PopoverModule,
    UploadsModule,
    ExcelModule,
    EditorModule,
  ],
  exports: [
    ButtonsModule,
    DateInputsModule,
    DialogsModule,
    WindowModule,
    DropDownsModule,
    GridModule,
    IconModule,
    SVGIconModule,
    IndicatorsModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    MenusModule,
    NotificationModule,
    PopoverModule,
    UploadsModule,
    ExcelModule,
    EditorModule,
  ],
})
export class KendoModule {}
