import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, mergeMap, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';

import { UtilService } from 'src/app/shared/util.service';
import { LocalStorage } from 'src/app/core/local-storage.enum';
import { Technician } from './technician.model';
import { ActivityCode } from './activity-code.model';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ServiceSharedService {
  private dialog!: DialogRef;

  constructor(private http: HttpClient, private dialogService: DialogService, private utilService: UtilService) {}

  // CACHE

  /**
   * cette fonction retourne la liste  des Technician  mis en cache dans le localStorage.
   * si  la cache n'A pas été rafraichie depuis x minutes, on la rafraichie.
   * on fetch les données de l'API de facon recursive pour obtenir tous les records.
   * @param forceFetch
   * @returns la liste de tous les technician
   */
  getListOfTechnician(forceFetch: boolean = false): Observable<Technician[]> {
    let fetch = false;
    // read localStorage
    let ls = localStorage.getItem(LocalStorage.CACHE_LIST_TECHNICIAN);
    if (forceFetch) {
      fetch = true;
    } else if (ls != null && ls.length > 0) {
      let data = JSON.parse(ls!);
      let now = Date.now();
      // force to refresh every X seconds
      if (now - data.lastUpdate > 5 * 60 * 1000) {
        fetch = true;
      }
    } else {
      // empty localstorage
      fetch = true;
    }
    // fetch or return cache data
    if (fetch) {
      //on fetch les techs,  et on tri pas IsActive desc, pour mettre les inactifs à la fin
      let myURI = apiUrl + 'services/Technician?$orderby=IsActive desc,technicianCode';
      return this.http.get<Technician[]>(myURI).pipe(
        mergeMap((response: any) => {
          let AllTech: Technician[] = [];

          //on va mapper les données de l'API dans notre model
          //ce n'est pas ODATAm, donc lit REPONSE directement
          response.value.forEach((element: any) => {
            let myTech = new Technician();
            myTech.TechnicianCode = element.technicianCode;
            myTech.Email = element.email;
            myTech.FullName = element.fullName;
            myTech.IsActive = element.isActive;

            //on ajoute la categorie a notre liste
            AllTech.push(myTech);
          });

          //on va pousser l'objet de cache (avec la date de mise a jour)
          let newCache = {
            lastUpdate: Date.now(),
            data: AllTech,
          };
          localStorage.setItem(LocalStorage.CACHE_LIST_TECHNICIAN, JSON.stringify(newCache));
          return of(AllTech);
        })
      );
    } else {
      let data = JSON.parse(ls!);
      return of(data.data);
    }
  }

  // CACHE

  /**
   * cette fonction retourne la liste  des Activity code  mis en cache dans le localStorage.
   * si  la cache n'A pas été rafraichie depuis x minutes, on la rafraichie.
   * on fetch les données de l'API de facon recursive pour obtenir tous les records.
   * @param forceFetch
   * @returns la liste de tous les Activity code
   */
  getListOfActivityCode(forceFetch: boolean = false): Observable<ActivityCode[]> {
    let fetch = false;
    // read localStorage
    let ls = localStorage.getItem(LocalStorage.CACHE_ACTIVITY_CODE);
    if (forceFetch) {
      fetch = true;
    } else if (ls != null && ls.length > 0) {
      let data = JSON.parse(ls!);
      let now = Date.now();
      // force to refresh every X seconds
      if (now - data.lastUpdate > 0.5 * 60 * 1000) {
        fetch = true;
      }
    } else {
      // empty localstorage
      fetch = true;
    }
    // fetch or return cache data
    if (fetch) {
      //on fetch les techs,  et on tri pas IsActive desc, pour mettre les inactifs à la fin
      let myURI = apiUrl + 'services/ActivityCode?$orderby=Code';
      return this.http.get<ActivityCode[]>(myURI).pipe(
        mergeMap((response: any) => {
          let AllRecord = response.value;
          //on va pousser l'objet de cache (avec la date de mise a jour)
          let newCache = {
            lastUpdate: Date.now(),
            data: AllRecord,
          };
          localStorage.setItem(LocalStorage.CACHE_ACTIVITY_CODE, JSON.stringify(newCache));
          return of(AllRecord);
        })
      );
    } else {
      let data = JSON.parse(ls!);
      return of(data.data);
    }
  }
}
