<div class="si-detail-item-container">
  <!-- HEADER -->
  <div class="si-detail-item-header">
    <!-- LEFT -->
    <div class="si-detail-item-header-left">
      <div class="si-detail-item-header-tech-date">
        <!-- TECHNICIEN -->
        <span class="si-detail-item-user"> {{ ticketDetail.scTechnicianCode }}: </span>
        <!-- DATE -->
        <span class="si-detail-item-date"> {{ ticketDetail.ticketDetailDate | date : "yyyy-MM-dd" }}</span>
        <!-- Si # de facture -->
        <div *ngIf="ticketDetail.invoiceNumber" class="si-detail-item-invoiceNumber">
          <span class="si-detail-item-user">{{ "module.ticket-detail.edit.label_nofacture" | translate }}: {{ ticketDetail.invoiceNumber }}</span>
        </div>
      </div>
      <div class="si-detail-item-header-ticket-category">
        <span class="si-detail-item-scInvoicingCategoryCode">
          <!-- CATEGORY -->
          {{ ticketDetail.scInvoicingCategoryCode }}<br />
          <!-- ACTIVITY -->
          {{ ticketDetail.activityId | getActivityCode : activityCodeList }}
        </span>
      </div>
    </div>

    <!-- RIGHT -->
    <div class="si-detail-item-header-right">
    
      <!-- TEMPS SOMMAIRE -->
      <div class="si-detail-item-time">
        <div>
          {{ "module.ticket-detail.edit.label_heure" | translate }} : {{ ticketDetail.realWorkedHours }}
          <span class="si-detail-item-time-real" *ngIf="ticketDetail.realWorkedHours != ticketDetail.billableWorkedHours"
            >({{ ticketDetail.billableWorkedHours }})</span
          >
        </div>
        <div>
          {{ "module.ticket-detail.edit.label_deplacement" | translate }} : {{ ticketDetail.realTravelledHours }}
          <span class="si-detail-item-time-real" *ngIf="ticketDetail.realTravelledHours != ticketDetail.billableTravelledHours"
            >({{ ticketDetail.billableTravelledHours }})</span
          >
        </div>
      </div>

      <!-- BUTTON CONTAINER -->

      <div class="si-detail-item-header-button">
        <!-- BOUTON EDIT COMMENTAIRE -->
        <!-- Si modifiable (ticket non fermé, et pas de # de facture) -->
        @if(!ticketHeader.isClosed && !ticketDetail.invoiceNumber) {
        <button kendoButton [svgIcon]="allIcons.pencilIcon" themeColor="secondary" (click)="edit()" type="button"></button>
        <!-- Si NON-modifiable : afficher "icon" facturé-->
        } @if(ticketDetail.invoiceNumber) {
        <button kendoButton [svgIcon]="allIcons.dollarIcon" [disabled]="true" themeColor="secondary" type="button"></button>
        }
      </div>
    </div>
  </div>
  <!-- COMMENTAIRE -->
  <div class="si-detail-item-input-comment-label">{{ "module.ticket-detail.edit.label_comment_servicentre" | translate }}</div>
  <div class="si-detail-item-comment-textarea" [innerHTML]="ticketDetail.servicentreComment"></div>
  <!--
  @if(ticketDetail.publicComment) {
  <div class="si-detail-item-input-comment-label">{{ "module.ticket-detail.edit.label_comment_public" | translate }}</div>
  <div class="si-detail-item-comment" [innerHTML]="ticketDetail.publicComment"></div>
  }
  -->
  @if(ticketDetail.internalComment) {
  <div class="si-detail-item-input-comment-label">{{ "module.ticket-detail.edit.label_comment_internal" | translate }}</div>
  <div class="si-detail-item-comment" [innerHTML]="ticketDetail.internalComment"></div>
  }
</div>
