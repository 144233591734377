import { Pipe, PipeTransform } from '@angular/core';
/**
 * Retourne le nom du technicien
 */
@Pipe({
  name: 'getTechnicianName',
})
export class TechnicianNamePipe implements PipeTransform {
  transform(key: any, technicianList: any): string {
    // chercher le nom si la liste n'est pas vide
    if (technicianList) {
      let name = technicianList
        .filter((c: any) => c.TechnicianCode == key)
        .map((c: any) => {
          return c.FullName;
        });

      if (name && name.length > 0) {
        return name[0];
      }
    }
    // on retourne le key si on ne trouve pas le nom
    return key;
  }
}
