<kendo-dialog-titlebar (close)="cancel()">
  <div class="si-popup-titlebar-container">
    {{ title | translate }}
  </div>
</kendo-dialog-titlebar>
<!-- CONTENT -->
<div class="si-dialog-content">
  <div class="si-pop-confirm-message" id="message"></div>
</div>
<!-- BUTTONS -->
<kendo-dialog-actions>
  <button kendoButton themeColor="primary" [svgIcon]="allIcons.xIcon" fillMode="flat" (click)="cancel()">
    {{ button_no | translate }}
  </button>
  <button kendoButton themeColor="primary" [svgIcon]="allIcons.checkIcon" (click)="confirm()">
    {{ button_yes | translate }}
  </button>
</kendo-dialog-actions>
