import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable, of } from 'rxjs';
import { mergeMap, tap, map, switchMap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State, toODataString } from '@progress/kendo-data-query';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';

import { ServiceContractPopupComponent } from './service-contract-popup/service-contract-popup.component';
import { ServiceContract } from './service-contract.model';
import { UtilService } from 'src/app/shared/util.service';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ServiceContractService {
  private dialog!: DialogRef;

  constructor(private http: HttpClient, private dialogService: DialogService, private utilService: UtilService) {}

  /*
   * retourne la liste de tous les contrats de services
   */
  list(): Observable<ServiceContract[]> {
    return this.http.get<ServiceContract[]>(apiUrl + 'AR/ServiceContract', {}).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ODATA  : STRING

  odataStr(state: State, oDataString: string): Observable<any> {
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    let uri = `${apiUrl}AR/ServiceContract?${oDataFullString}&$count=true`;
    return this.http.get<any>(uri);
  }

  // POPUP CUSTOMER LIST

  public popup(): any {
    const confirmObservable = new Observable((observer) => {
      // open dialog
      this.dialog = this.dialogService.open({
        content: ServiceContractPopupComponent,
        minWidth: 400,
        height: 800,
        maxWidth: 1300,
        cssClass: 'si-dialog-grid',
      });
      // set default values
      const dialogData = this.dialog.content.instance as ServiceContractPopupComponent;
      dialogData.title = 'module.service-contract.popup.title';
      // subscribe to response
      this.dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          // cancel
          observer.next(false);
        } else {
          // confirm
          observer.next(result);
        }
      });
    });
    return confirmObservable;
  }
}
