import { Component, OnInit, Output, EventEmitter, inject, Input, ViewChildren, QueryList, ViewChild, AfterViewInit } from '@angular/core';
import { GridComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { State } from '@progress/kendo-data-query';
import { GridDataResult, RowArgs } from '@progress/kendo-angular-grid';
import { Subject, takeUntil, catchError, forkJoin, Observable, of } from 'rxjs';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import * as svgIcons from '@progress/kendo-svg-icons';

import { TicketHeaderService } from '../ticket-header.service';
import { GridService } from 'src/app/shared/grid.service';
import { PermissionService } from 'src/app/core/permission.service';
import { CustomerService } from 'src/app/module/account-receivable/customer/customer.service';
import { TicketTypeService } from 'src/app/module/service/ticket-type/ticket-type.service';
import { TicketStatusService } from 'src/app/module/service/ticket-status/ticket-status.service';
import { ServiceSharedService } from '../../../shared/service-shared-services';
import { Technician } from '../../../shared/technician.model';
import { TicketHeader } from '../ticket-header.model';
import { gridColumnConfig } from 'src/app/shared/grid/grid-column-config.model';
import { DepartmentService } from '../../../department/department.service';
import { Department } from '../../../department/department.model';
import { UtilService } from 'src/app/shared/util.service';
import { DynamicEntityService } from 'src/app/module/servicentre/dynamic-entity.service';
import { InvoicingCategory } from 'src/app/module/service/invoicing-category/invoicing-category.model';

@Component({
  selector: 'app-ticket-header-list',
  templateUrl: './ticket-header-list.component.html',
  styleUrls: ['./ticket-header-list.component.scss'],
})
export class TicketHeaderListComponent implements OnInit, AfterViewInit {
  private ticketHeaderService = inject(TicketHeaderService);
  private ticketTypeService = inject(TicketTypeService);
  private departmentService = inject(DepartmentService);
  private ticketStatusService = inject(TicketStatusService);
  private customerService = inject(CustomerService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private location = inject(Location);
  private serviceSharedService = inject(ServiceSharedService);
  public gridService = inject(GridService);
  private utilService = inject(UtilService);
  private dynamicServicentreEntity = inject(DynamicEntityService);
  public allIcons = svgIcons;

  @Input() isPopupMode: boolean = false;

  @Output() add = new EventEmitter();
  @Output() edit = new EventEmitter();

  @ViewChild(GridComponent) public myMainGrid!: GridComponent;

  currentSelectedTicketId: number = 0;
  isLoading = false;
  gridView!: GridDataResult;
  currentPresetName: string = 'preset1';
  presetData: any = {};
  pageable = {
    pageSizes: this.gridService.pageSizes,
  };
  public state: State = {
    take: this.gridService.take,
    skip: 0,
    sort: [
      {
        field: 'openingDate',
        dir: 'desc',
      },
      {
        field: 'ticketId',
        dir: 'desc',
      },
    ],
  };

  /**Liste des configs de colonnes du grid.  Juste besoin d'instancier les colonnes  */
  public columnConfigDictionary: { [key: string]: gridColumnConfig } = {};
  /**Réplicat des colonnes, assigné automatiquement, mais axé sur les filters.  permet d'être référencé par le template sans soucis de nullificatio (TODO OPTIMIZE).  */
  public columnConfigDictionaryFilters: { [key: string]: any } = {};

  ticket: any = [];
  public customerList: any;
  public customerFilterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  public ticketTypeList: any;
  public ticketTypeFilterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  public ticketStatusList: any;
  public ticketStatusFilterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  invoicingCategoryList: InvoicingCategory[] = [];
  public invoicingCategoryFilterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  //Liste des départements disponibles
  public listAvailableDepartements: Department[] = [];

  private unsubNotifier = new Subject<void>();
  private permissionService = inject(PermissionService);

  technicianList: Technician[] = [];

  constructor() {
    // if a record is selected open EDIT form
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id) {
      this.ticketHeaderService
        .read(+id)
        .pipe(takeUntil(this.unsubNotifier))
        .subscribe((response) => {
          this.edit.emit();
          this.ticket = response;
        });
    }
    // fetch la liste des TechnicianCode pour dropdown filter
    this.serviceSharedService
      .getListOfTechnician(false)
      .pipe(takeUntil(this.unsubNotifier))
      .subscribe((response: Technician[]) => {
        this.technicianList = response;
        // mettre en ordre alphabetique
        this.technicianList.sort((a: any, b: any) => {
          if (a.FullName < b.FullName) return -1;
          if (a.FullName > b.FullName) return 1;
          return 0;
        });
        this.technicianList.unshift({ TechnicianCode: '', FullName: '', Email: '', IsActive: false });
      });
    // fetch du data
    forkJoin({
      customerList: this.customerService.listCache(),
      ticketTypeList: this.ticketTypeService.listCache(),
      ticketStatusList: this.ticketStatusService.listCache(),
      listAvailableDepartements: this.departmentService.listCache(),
      ticketCustomerList: this.ticketHeaderService.odataStr({}, '$apply=groupby((customerCode))&$select=customerCode'),
      invoicingCategoryList: this.dynamicServicentreEntity.getListOfInvoicingCategory(false),
    })
      .pipe(takeUntil(this.unsubNotifier))
      .subscribe((response: any) => {
        // customer list pour filtre dropdown
        this.customerList = response.ticketCustomerList.map((c: any) => {
          return {
            noClient: c.customerCode,
            nomClient: this.customerService.getCustomerName(c.customerCode),
          };
        });
        // insert empty customer in customerList (pour pouvoir reseter le filtre)
        this.customerList.unshift({ noClient: '', nomClient: '' });
        // sort customerList by nomClient
        this.customerList.sort((a: any, b: any) => {
          if (a.nomClient < b.nomClient) return -1;
          if (a.nomClient > b.nomClient) return 1;
          return 0;
        });

        // ticket type pour filtre dropdown
        this.ticketTypeList = response.ticketTypeList;

        // filtre departement
        this.listAvailableDepartements = response.listAvailableDepartements;
        this.listAvailableDepartements.unshift({ departmentShortCode: '', nameFr: '', nameEn: '' });

        // ticket status pour filtre dropdown
        this.ticketStatusList = response.ticketStatusList;
        this.ticketStatusList.push({ ServicentreTextualCode: 'FACTURE' });
        this.ticketStatusList.sort((a: any, b: any) => {
          if (a.ServicentreTextualCode < b.ServicentreTextualCode) return -1;
          if (a.ServicentreTextualCode > b.ServicentreTextualCode) return 1;
          return 0;
        });

        // filtre cateogrie de facturation
        this.invoicingCategoryList = response.invoicingCategoryList;
      });
  }

  ngOnInit(): void {
    this.loadGridPreset('preset1');
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.unsubNotifier.next();
    this.unsubNotifier.unsubscribe();
  }

  /*
  ajouter() {
    this.ticketHeaderService.initOne();
    this.add.emit();
  }
  */

  onCellClick(e: any) {
    this.currentSelectedTicketId = e.dataItem.ticketId;

    //en mode 'popup', on veut pas déclencher un refresh dès qu'on clique sur un élément... on va vouloir le SÉLECTIONNER ..
    if (this.isPopupMode) return;

    this.ticketHeaderService
      .read(e.dataItem.ticketId)
      .pipe(takeUntil(this.unsubNotifier))
      .subscribe((response) => {
        this.ticket = response;
      });

    this.edit.emit();
    this.location.replaceState('ticket/' + e.dataItem.ticketId);
  }

  /**
   * Cette fonction est appelée lorsque le state de la grille change (filtre, tri, pagination, etc.)
   */
  dataStateChange(state: State) {
    this.gridService.setRowPerPages(state);
    this.state = state;
    this.refresh();
  }

  /**
   * cette fonction rafraichit la grille
   */
  refresh() {
    // fetch data
    this.isLoading = true;
    this.ticketHeaderService
      .odataStr(
        this.state,
        '$select=ticketId,customerCode,scTicketShortTitle,scTicketType,sC_Ticket_AssignedTo_UserCode,sC_Ticket_CreatedBy_UserCode,scTicketStatusCode,serviceContractId,scInvoicingCategoryCode,billableSubTotalAmount,departmentId,openingDate,isClosed'
      )
      .pipe(
        catchError((err: any) => {
          this.isLoading = false;
          throw err;
        }),
        takeUntil(this.unsubNotifier)
      )
      .subscribe((response) => {
        this.isLoading = false;
        this.gridView = {
          data: response.value,
          total: response['@odata.count'],
        };
      });
  }

  /*
   * Sert a highlighter la ligne sélectionnée
   */
  isRowSelected = (e: RowArgs) => {
    return e.dataItem.ticketId == this.currentSelectedTicketId;
  };

  /**
   * fonction générique pour appliquer un filtre sur une colonne
   * @param fieldName nom du champs sur lequel on veut appliquer le filtre
   * @param value valeur du filtre
   */
  filterChange(fieldName: string, value: any) {
    this.state = this.gridService.applyFilterToState(this.state, fieldName, value);
    this.dataStateChange(this.state);
  }

  /**
   * fonction générique pour appliquer un filtre sur une colonne pour un filtre de type multiselect
   * @param fieldName nom du champs sur lequel on veut appliquer le filtre
   * @param values valeurs du filtre
   */
  filterMultiSelectChange(values: any[], fieldName: string, idPropertyName: string) {
    if (!this.state.filter) {
      // si aucun filtre n'était défini, créer le filtre vide
      this.state.filter = { logic: 'and', filters: [] };
    } else {
      // si il y a deja un filtre, on le refait au complet
      var newFilters: any[] = [];
      const currentFilters = this.state.filter.filters as Array<any>;
      // loop tous les filtres actif et enlever le filtre sur le champ fieldName
      currentFilters.forEach((f) => {
        if (f.filters && f.filters.length > 0) {
          // si c'est un filtre multi on vérifie si c'est le même champ, sinon on garde
          const myFilters = f.filters.filter((sf: any) => sf.field == fieldName);
          if (myFilters.length == 0) {
            newFilters.push(f);
          }
        } else {
          // si c'est un filtre normal on garde toujours
          newFilters.push(f);
        }
      });
      this.state.filter.filters = newFilters;
    }
    // ajout du filtre multi
    var oDataFilter = this.utilService.buildMultiSelectFilter(values, fieldName, idPropertyName);
    if (oDataFilter !== false && this.state.filter) this.state.filter.filters.push(oDataFilter);
    // trigger state change
    this.dataStateChange(this.state);
  }

  /*
   * Affiche la liste de tous les commentaires du ticket sélectionné
   */
  showTicketComments(event: Event, dataItem: TicketHeader) {
    event.stopPropagation();
    this.router.navigate(['/ticket/ticket-detail'], { queryParams: { ticketId: dataItem.ticketId } });
  }

  /*
   * Cette fonction est appelée lorsque l'utilisateur change la visibilité d'une colonne
   * on sauvegarde le state au complet dans le local storage
   */
  public onColumnVisibilityChange(event: any): void {}

  /**Cette méthode load un preset Persisted, et va tenter de l'appliquer au GRID et objets locaux */
  public loadGridPreset(presetName: string) {
    /**Chargement du preset */
    this.presetData = this.gridService.loadGridPreset(presetName);

    if (this.presetData) {
      //le state "brute" pour le filtrage aussi complexe soit-il de la grille
      this.state = this.presetData.state;

      //la liste des config de colonnes.  surtout pour largeur, hidden
      this.columnConfigDictionary = this.presetData.myGridColumnsConfig;

      //pour chaque colonne enregistré dans mon preset...
      for (const key of Object.keys(this.columnConfigDictionary)) {
        if (this.columnConfigDictionary[key].currentFilterApplied === undefined) {
          this.columnConfigDictionary[key].currentFilterApplied = null;
        }
        //il faut absolument instancier les filtres sinon on a des erreurs de type
        this.columnConfigDictionaryFilters[key] = this.columnConfigDictionary[key].currentFilterApplied;

        //si le grid existe, on va appliquer les configs de colonnes SUR les colonnes du grid
        if (this.myMainGrid) {
          //on passe chaque colonne du grid
          this.myMainGrid.columns.forEach((column: any) => {
            //si on a un match sur le field de la colonne du grid et le field de la config de colonne
            if (column.field == key) {
              column.width = this.columnConfigDictionary[key].columnWidth;
              column.hidden = this.columnConfigDictionary[key].hidden;
            }
          });
        }
      }
    }

    this.refresh();
  }

  /**
   * Cette fonction sauvegarde le state et la config des colonnes dans le local storage
   */
  public saveGridPreset() {
    //si le grid n'existe pas, on ne peut pas sauvegarder
    if (this.myMainGrid == null || this.myMainGrid == undefined) return;

    //pour chaque colonne du grid
    this.myMainGrid.columns.toArray().map((kendoColumn: any) => {
      let fieldName = kendoColumn['field'];

      //on regarde si la colonne existe déjà dans la config de colonnes, et si non, on va la créer
      if (!this.columnConfigDictionary[fieldName]) {
        this.columnConfigDictionary[fieldName] = <gridColumnConfig>{ field: fieldName, currentFilterApplied: null };
      }

      if (this.columnConfigDictionary[fieldName].currentFilterApplied === undefined) this.columnConfigDictionary[fieldName].currentFilterApplied = null;

      //on map les valeurs de la colonne du grid dans la config de colonnes
      this.columnConfigDictionary[fieldName].hidden = kendoColumn['hidden'];
      this.columnConfigDictionary[fieldName].columnGridPosition = kendoColumn['leafIndex'];
      this.columnConfigDictionary[fieldName].columnWidth = kendoColumn['width'];

      //le filtrage est explicitement décallé, car il fait partit de plusiuers ngModel du template, et ce dernier est frileux à accéder à une propriété d'un membre (clé) qui n'existe pas
      this.columnConfigDictionary[fieldName].currentFilterApplied = this.columnConfigDictionaryFilters[fieldName];
    });

    //on fabrique l'objet global de sauvegarde
    let saveObj = {
      state: this.state,
      myGridColumnsConfig: this.columnConfigDictionary,
    };
    this.gridService.saveGridPreset(saveObj);
    this.presetData = saveObj;
  }
}
