export class Customer {
  public noUnique: number = 0;
  public noClient: string = '';
  public nomClient: string = '';
  public estActif: string = '';
  public nomPersonneContact: string = '';
  public ville: string = '';
  public telephone1: string = '';
  public emailPrincipalEmail: string = '';
  constructor() {}
}
