import { Component, OnInit, inject } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import * as svgIcons from '@progress/kendo-svg-icons';
import { Subject, takeUntil, catchError, Subscription, forkJoin } from 'rxjs';

import { ServiceContractService } from '../service-contract.service';
import { GridService } from 'src/app/shared/grid.service';
import { CustomerService } from '../../customer/customer.service';
import { Customer } from '../../customer/customer.model';

@Component({
  selector: 'app-service-contract-popup',
  templateUrl: './service-contract-popup.component.html',
  styleUrls: ['./service-contract-popup.component.scss'],
})
export class ServiceContractPopupComponent extends DialogContentBase implements OnInit {
  private customerService = inject(CustomerService);
  private serviceContractService = inject(ServiceContractService);

  public allIcons = svgIcons;

  public customerList: any = [];
  public customerFilterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  title = '';

  isLoading = false;
  gridView!: GridDataResult;
  id!: number;
  oDataStr =
    '$select=serviceContractId,serviceContractId,contractStartDate,contractEndDate,customerCode,scContractType,scInvoicingMode,internalShortNote,periodTotalAmt';
  pageable = {
    pageSizes: this.gridService.pageSizes,
  };

  public state: State = {
    take: this.gridService.take,
    skip: 0,
  };

  constructor(public override dialog: DialogRef, public gridService: GridService) {
    super(dialog);
    // fetch data
    this.isLoading = true;
    forkJoin({
      customerList: this.customerService.listCache(),
      serviceContractCustomerList: this.serviceContractService.odataStr({}, '$apply=groupby((customerCode))&$select=customerCode'),
      serviceContractList: this.serviceContractService.odataStr(this.state, this.oDataStr),
    }).subscribe((response: any) => {
      this.isLoading = false;
      // customer list pour filtre dropdown
      this.customerList = response.serviceContractCustomerList.map((c: any) => {
        return {
          noClient: c.customerCode,
          nomClient: this.customerService.getCustomerName(c.customerCode),
        };
      });
      // insert empty customer in customerList (pour pouvoir reseter le filtre)
      this.customerList.unshift({ noClient: '', nomClient: '' });
      // sort customerList by nomClient
      this.customerList.sort((a: any, b: any) => {
        if (a.nomClient < b.nomClient) return -1;
        if (a.nomClient > b.nomClient) return 1;
        return 0;
      });
      // serviceContract list
      this.gridView = {
        data: response.serviceContractList.value,
        total: response.serviceContractList['@odata.count'],
      };
    });
  }

  ngOnInit(): void {}

  cancel() {
    this.dialog.close(false);
  }

  select(e: any) {
    this.dialog.close(e.dataItem);
  }

  dataStateChange(state: State) {
    this.isLoading = true;
    this.gridService.setRowPerPages(state);
    this.state = state;
    this.serviceContractService.odataStr(this.state, this.oDataStr).subscribe((response) => {
      this.isLoading = false;
      this.gridView = {
        data: response.value,
        total: response['@odata.count'],
      };
    });
  }

  /**
   * fonction générique pour appliquer un filtre sur une colonne
   * @param fieldName nom du champs sur lequel on veut appliquer le filtre
   * @param value valeur du filtre
   */
  filterChange(fieldName: string, value: any) {
    this.state = this.gridService.applyFilterToState(this.state, fieldName, value);
    this.dataStateChange(this.state);
  }
}
