import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy, inject, Input, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { catchError, Observable, take, takeUntil, Subject, tap, switchMap, pipe } from 'rxjs';
import { Location } from '@angular/common';
import * as svgIcons from '@progress/kendo-svg-icons';
import { State } from '@progress/kendo-data-query';

import { TicketHeaderService } from '../ticket-header.service';
import { TicketHeader } from '../ticket-header.model';
import { TicketDetailService } from '../../ticket-detail/ticket-detail.service';
import { TicketDetail } from '../../ticket-detail/ticket-detail.model';
import { UtilService } from 'src/app/shared/util.service';
import { CustomerService } from 'src/app/module/account-receivable/customer/customer.service';
import { GridService } from 'src/app/shared/grid.service';
import { TicketEditConfiguration } from '../../../shared/ticket-edit-configuration.model';
import { ActivityCode } from '../../../shared/activity-code.model';
import { Technician } from '../../../shared/technician.model';
import { InvoicingCategory } from '../../../invoicing-category/invoicing-category.model';
import { ServiceSharedService } from '../../../shared/service-shared-services';
import { DynamicEntityService } from 'src/app/module/servicentre/dynamic-entity.service';

@Component({
  selector: 'app-ticket-header-edit',
  templateUrl: './ticket-header-edit.component.html',
  styleUrls: ['./ticket-header-edit.component.scss'],
})
export class TicketHeaderEditComponent implements OnInit, OnDestroy {
  private ticketHeaderService = inject(TicketHeaderService);
  private customerService = inject(CustomerService);
  private ticketDetailService = inject(TicketDetailService);
  private serviceSharedService = inject(ServiceSharedService);
  private dynamicServicentreEntity = inject(DynamicEntityService);

  public allIcons = svgIcons;

  //quand un parent veut passer un ticket en mode "ajout" (pour un nouveau ticket)
  @Input() newTicketDetail!: TicketDetail;
  @Input() public ticketEditConfiguration: TicketEditConfiguration = new TicketEditConfiguration();

  @Output() closeEditPanel = new EventEmitter();
  @Output() refreshFdtList = new EventEmitter();
  @Output() updateFormDirtyState = new EventEmitter();
  @ViewChild(NgForm) editform!: NgForm;

  customerName = '';
  ticketHeader: TicketHeader = new TicketHeader();
  isLoading = false;
  isChangeEmitted = false;
  ticketDetailList: TicketDetail[] = [];
  scTechnicianCode: any;
  ticketDetailDate: any;
  ticketDetailDateStart: any;
  ticketDetailDateEnd: any;
  showAllCommentsMode = false;
  activityCodeList: ActivityCode[] = [];
  technicianList: Technician[] = [];
  invoicingCategoryList: InvoicingCategory[] = [];
  public totalHours: number = 0;
  private unsubNotifier = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute, private location: Location, private utilService: UtilService) {
    this.ticketHeaderService.initOne();

    // subscribe to data ticket "header"
    this.ticketHeaderService.ticket.pipe(takeUntil(this.unsubNotifier)).subscribe((response) => {
      if (!response) {
        this.ticketHeader = new TicketHeader();
        this.ticketHeader.ticketId = -1;
        this.ticketHeader.scTicketShortTitle = 'Ticket non trouvé';
        this.ticketHeader.isClosed = true;
        this.totalHours = 0;
      } else {
        // set data
        this.ticketHeader = response;
        this.totalHours = response.totalWorkedHours + response.totalTravelledHours;
      }
      // reset form
      this.isChangeEmitted = false;
      if (this.editform) this.editform.control.markAsPristine();
      // si un ticket est sélectionné
      if (this.ticketHeader.ticketId != 0) {
        // get customer name
        this.customerService
          .listCache()
          .pipe(takeUntil(this.unsubNotifier))
          .subscribe(() => {
            this.customerName = this.customerService.getCustomerName(this.ticketHeader.customerCode);
          });
        this.refreshTicketDetailList();
      }
    });

    this.serviceSharedService.getListOfActivityCode().subscribe((response) => {
      this.activityCodeList = response;
    });

    this.serviceSharedService.getListOfTechnician().subscribe((response) => {
      this.technicianList = response;
    });

    this.dynamicServicentreEntity
      .getListOfInvoicingCategory(false)
      .pipe(takeUntil(this.unsubNotifier))
      .subscribe((response: InvoicingCategory[]) => {
        this.invoicingCategoryList = response;
      });
  }

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(
        catchError((err: any) => {
          this.isLoading = false;
          throw err;
        }),
        takeUntil(this.unsubNotifier)
      )
      .subscribe((response: any) => {
        if (response!.ticket) {
          this.isLoading = false;
          this.ticketHeader = response.ticket;
        }
      });
  }

  refreshTicketDetailList() {
    this.showAllCommentsMode = false;
    // read url param to get ticket details
    this.scTechnicianCode = this.utilService.getUrlParameter('scTechnicianCode');

    this.gererDateDebutFin();

    // build odata query string
    var oDataQuery = `filter=TicketId eq ${this.ticketHeader.ticketId}`;
    if (this.scTechnicianCode) oDataQuery += ` and scTechnicianCode eq '${this.scTechnicianCode}'`;

    //gestion de la date
    if (this.ticketEditConfiguration.minTicketDetailDate.toISOString() === this.ticketEditConfiguration.maxTicketDetailDate.toISOString()) {
      oDataQuery += ` and TicketDetailDate eq ${this.ticketDetailDate}`;
    } else if (this.ticketEditConfiguration.maxTicketDetailDate.getFullYear() < 2099) {
      oDataQuery += ` and TicketDetailDate ge ${this.ticketDetailDateStart} and TicketDetailDate le ${this.ticketDetailDateEnd}`;
    }
    oDataQuery += `&orderby=TicketDetailDate desc, lineNumber desc`;
    // fetch ticket detail data
    this.isLoading = true;
    this.ticketDetailService
      .odataStr({}, oDataQuery)
      .pipe(takeUntil(this.unsubNotifier))
      .subscribe((detail) => {
        this.isLoading = false;
        this.ticketDetailList = detail.value;
      });
  }

  /*
   * Rafraichit le header du ticket, permet de rafraichir le Total d'heures travaillées (unique utilité pour le moment)
   */
  refreshTicketHeader() {
    this.ticketHeaderService
      .read(this.ticketHeader.ticketId)
      .pipe(takeUntil(this.unsubNotifier))
      .subscribe((response) => {});
  }

  ngOnDestroy(): void {
    this.unsubNotifier.next();
    this.unsubNotifier.unsubscribe();
  }

  /*
   * Cette fonction refresh tout les composants de l'écran (header, liste des commentaires, feuille de temps)
   */
  refreshScreenData() {
    // refresh TOTAL HRS
    this.refreshTicketHeader();
    // refresh la liste des commentaires
    this.refreshTicketDetailList();
    // refresh la feuille de temps (totaux)
    this.refreshFdtList.emit();
  }

  ngDoCheck(changes: any) {
    if (!this.isChangeEmitted && this.editform?.pristine === false) {
      this.isChangeEmitted = true;
      this.updateFormDirtyState.emit(true);
    }
  }

  onSubmit(form: NgForm) {}

  fermer() {
    this.popConfirmExit().subscribe((approved) => {
      if (approved) this.annuler();
    });
  }

  public popConfirmExit(): Observable<boolean> {
    const confirmObservable = new Observable<boolean>((observer) => {
      if (!this.editform.pristine) {
        this.utilService
          .confirm(
            'shared.edit.popup_form_changed.title',
            'shared.edit.popup_form_changed.message',
            'shared.edit.popup_form_changed.yes',
            'shared.edit.popup_form_changed.no'
          )
          .subscribe((approved: boolean) => {
            observer.next(approved);
            observer.complete();
          });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
    return confirmObservable;
  }

  annuler() {
    this.editform.control.markAsPristine();
    this.location.replaceState('ticket/');
    this.closeEditPanel.emit();
  }

  /*
   * Cette fonction lit les paramètres de l'URL pour déterminer les dates de début et de fin affiché dans laliste des commentaires.
   */
  gererDateDebutFin() {
    this.ticketDetailDate = this.utilService.getUrlParameter('ticketDetailDate');
    this.ticketDetailDateStart = this.utilService.getUrlParameter('ticketDetailDateStart');
    this.ticketDetailDateEnd = this.utilService.getUrlParameter('ticketDetailDateEnd');

    //une seule date
    if (this.ticketDetailDate) {
      this.ticketEditConfiguration.minTicketDetailDate = this.utilService.formatStringToDate(this.ticketDetailDate);
      this.ticketEditConfiguration.maxTicketDetailDate = this.utilService.formatStringToDate(this.ticketDetailDate);
    }
    //un RANGE de date
    else if (this.ticketDetailDateStart && this.ticketDetailDateEnd) {
      this.ticketEditConfiguration.minTicketDetailDate = this.utilService.formatStringToDate(this.ticketDetailDateStart);
      this.ticketEditConfiguration.maxTicketDetailDate = this.utilService.formatStringToDate(this.ticketDetailDateEnd);
    } // aucune limitation de date
    else {
      this.ticketEditConfiguration.minTicketDetailDate = new Date(1900, 1, 1);
      this.ticketEditConfiguration.maxTicketDetailDate = new Date(2099, 12, 31);
    }
  }

  //**Bouton qui déclenche l'ajout d'un commentaire.  on va INITIER un TicketDetail ici, que le composant Ticket-Edit pourra poursuivre. */
  public addComment(newTicketDetailData?: TicketDetail) {
    console.log('addComment', newTicketDetailData);

    //NOTE 2024-06-25 : ça marche mais on devrait pouvoir utiliser newTicketDetailData pour créer le data
    // la ca marche parce qu'une partie du data est setter dans l'URL (date + operateur)
    // ticketid est bon parce que ticket header edit l'initalise

    // set default values
    let newTicketDetail = new TicketDetail();
    newTicketDetail.scInvoicingCategoryCode = this.ticketHeader.scInvoicingCategoryCode;
    newTicketDetail.ticketId = this.ticketHeader.ticketId;
    newTicketDetail.scTechnicianCode = this.scTechnicianCode;
    if (newTicketDetail.scTechnicianCode == '' || newTicketDetail.scTechnicianCode == null) {
      newTicketDetail.scTechnicianCode = this.ticketHeader.sC_Ticket_CreatedBy_UserCode;
    }

    this.gererDateDebutFin();

    let DateToUse = new Date().toISOString();
    //si la date de fin est plus basse que getFullYear, alors c'est soit un range, soit une date unique...auxquel cas, on veut prednre cette date
    if (this.ticketEditConfiguration.maxTicketDetailDate.getFullYear() < 2099) {
      DateToUse = this.ticketEditConfiguration.maxTicketDetailDate.toISOString();
    }

    if (DateToUse == '' || DateToUse == null) {
      DateToUse = new Date().toISOString();
    }

    let date: Date = this.utilService.formatStringToDate(DateToUse);
    newTicketDetail.ticketDetailDate = date;

    this.ticketDetailService.popupEdit(newTicketDetail).subscribe((result: any) => {
      if (result !== false) {
        this.refreshScreenData();
      }
    });
  }

  showAllComments() {
    this.location.replaceState('fdt/' + this.ticketHeader.ticketId);
    this.refreshTicketDetailList();
    this.showAllCommentsMode = true;
  }

  refreshFdtListComponent() {
    this.isLoading = false;
    this.refreshFdtList.emit();
  }

  isLoadingChange(event: any) {
    this.isLoading = event;
  }

  get closedStatusDisplay(): string {
    return this.ticketHeader.isClosed ? 'FERMÉ' : 'OUVERT';
  }

  /*
   * Cette fonction est appellée par le composant EDIT, si un un commentaire est en mode edit, on bloque la grille.
   */
  public updateEditFormDirtyState(event: any) {
    this.updateFormDirtyState.emit(event);
  }

  /*
   * Enlève un commentaire de la liste des commentaires.
   */
  removeCommentaire(ticketDetail: TicketDetail) {
    this.ticketDetailList = this.ticketDetailList.filter((item) => item.ticketId !== ticketDetail.ticketId && item.lineNumber !== ticketDetail.lineNumber);
  }
}
