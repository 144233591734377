/**
 * Cette classe représente une LIGNE d'un d'inlet.  c'est ici qu'on y retrouve le TEMPS, les commentaires, etc..
 *
 */
export class TicketDetail {
  /**
   * Numéro du ticket parent
   */
  public ticketId?: number = 0;

  /**
   * Numéro de la ligne
   */
  public lineNumber?: number | undefined;

  /**
   * Date de la ligne
   */
  public ticketDetailDate?: Date | undefined;

  /**
   * Code du technicien
   */
  public scTechnicianCode?: string = '';

  /**
   * Code de la catégorie de facturation (501-PROG-SENIOR, 500-PROG-REG, PROJET-TI, etc..)
   */
  public scInvoicingCategoryCode?: string = '';
  //public billableDepartureTime?: number = 0;
  //public billableWorkTimeStart?: number = 0;
  //public billableWorkTimeEnd?: number = 0;
  //public billableReturnTime?: number = 0;

  /**
   * Nombre d'heures travaillées FACTURABLES.  EXCLUERA les heures de déplacement (facturable) pas géré as of 2024-03-xx
   */
  public billableWorkedHours?: number = 0;
  //public billableTotalHours?: number = 0;
  //public nbKilometre?: number = 0;
  //public travelHourlyRate?: number = 0;
  //public workHourlyRate?: number = 0;

  /**
   * Numéro de la facture.  Si cette valeur est remplit, la ligne est TOTALEMENT NON MODIFIABLE!
   */
  public invoiceNumber?: string = '';
  //public realDepartureTimeStart?: number = 0;
  //public realWorkTime?: number = 0;
  //public realWorkTimeEnd?: number = 0;
  //public realReturnTime?: number = 0;

  /**
   * Nombre d'heures travaillées RÉELLES.  EXCLUERA les heures de déplacement (réelle), pas géré as of 2024-03-xx
   */
  public realWorkedHours?: number = 0;
  //public realTotalHours?: number = 0;

  /*
   * Nombre d'heures de déplacement facturables
   */
  public billableTravelledHours?: number = 0;

  /*
   * Nombre d'heures de déplacement réels
   */
  public realTravelledHours?: number = 0;

  /**
   * ID du code d'activité
   */
  public activityId?: number = 0;

  /**
   * Coût de la ligne
   */
  public ticketDetailCost?: number = 0;

  /**
   * Commentaire HTML visible par le client ou le public
   */
  public publicComment?: string = '';

  /**
   * Commentaire HTML INVISIBLE, MASQUÉS pour le client ou le public
   */

  public internalComment?: string = '';

  /**
   * Commentaire  TXT brut qui ira dans Servicentre
   */
  public servicentreComment?: string = '';

  constructor() {}
}
