<kendo-dialog-titlebar (close)="cancel()">
  <div class="si-popup-titlebar-container">
    {{ title | translate }}
  </div>
</kendo-dialog-titlebar>
<!-- CONTENT -->

<div class="si-popup-container">
  <kendo-grid
    [data]="gridView"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="pageable"
    [filterable]="true"
    [resizable]="true"
    [selectable]="gridService.selectableSettings"
    scrollable="scrollable"
    [loading]="isLoading"
    class="si-list-grid"
    (cellClick)="select($event)"
    (dataStateChange)="dataStateChange($event)"
  >
    <!-- COLUMNS -->

    <!-- NO CLIENT -->

    <kendo-grid-column field="noClient" title="{{ 'module.customer.model.noClient' | translate }}" [width]="80"></kendo-grid-column>

    <!-- NOM -->

    <kendo-grid-column field="nomClient" title="{{ 'module.customer.model.nomClient' | translate }}" [width]="180"></kendo-grid-column>

    <!-- NOM PERSONNE CONTACT-->

    <kendo-grid-column field="nomPersonneContact" title="{{ 'module.customer.model.nomPersonneContact' | translate }}" [width]="100"></kendo-grid-column>

    <!-- TELEPHONE1 -->

    <kendo-grid-column field="telephone1" title="{{ 'module.customer.model.telephone1' | translate }}" [width]="90"></kendo-grid-column>

    <!-- VILLE -->

    <kendo-grid-column field="ville" title="{{ 'module.customer.model.ville' | translate }}" [width]="80"></kendo-grid-column>

    <!-- ADRESS -->

    <kendo-grid-column field="adresse" title="{{ 'module.customer.model.adresse' | translate }}" [width]="100"></kendo-grid-column>

    <!-- IS ACTIVE

    <kendo-grid-column
      field="isActiveInSafestock"
      title="{{ 'module.customer.model.isActiveInSafestock' | translate }}"
      [width]="20"
      filter="boolean"
      ><ng-template kendoGridCellTemplate let-dataItem>
        <kendo-svg-icon
          *ngIf="dataItem.isActiveInSafestock"
          [icon]="allIcons.checkIcon"
          themeColor="primary"
        ></kendo-svg-icon> </ng-template
    ></kendo-grid-column>
     -->
  </kendo-grid>
</div>
