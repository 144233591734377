import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter, inject, SimpleChanges, input } from '@angular/core';
import * as svgIcons from '@progress/kendo-svg-icons';
import { catchError, Observable, take, takeUntil, Subject, tap, switchMap } from 'rxjs';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

import { TicketDetailService } from '../ticket-detail.service';
import { UtilService } from 'src/app/shared/util.service';
import { TicketDetail } from '../ticket-detail.model';
import { TicketHeader } from '../../ticket-header/ticket-header.model';
import { InvoicingCategory } from '../../../invoicing-category/invoicing-category.model';
import { TicketEditConfiguration } from '../../../shared/ticket-edit-configuration.model';
import { Technician } from '../../../shared/technician.model';
import { ActivityCode } from '../../../shared/activity-code.model';

@Component({
  selector: 'app-ticket-detail-view',
  templateUrl: './ticket-detail-view.component.html',
  styleUrls: ['./ticket-detail-view.component.scss'],
})
export class TicketDetailViewComponent implements OnInit, OnChanges {
  private ticketDetailService = inject(TicketDetailService);
  private utilService = inject(UtilService);

  private unsubNotifier = new Subject<void>();

  @Input() public ticketEditConfiguration: TicketEditConfiguration = new TicketEditConfiguration();

  @Input() isLoading = false;
  @Input() isNewTicketDetailMode = false; //si on est en mode ajout.
  @Input() ticketHeader!: TicketHeader;
  @Input() ticketDetail!: TicketDetail;
  @Input() activityCodeList: ActivityCode[] = [];
  @Input() invoicingCategoryList: InvoicingCategory[] = [];
  @Input() technicianList: Technician[] = [];

  @Output() refreshFdtList = new EventEmitter();
  @Output() refreshEditList = new EventEmitter();
  @Output() refreshTicketDetailList = new EventEmitter();
  @Output() refreshTicketHeader = new EventEmitter();
  @Output() isLoadingChange = new EventEmitter<boolean>();
  @Output() updateEditFormDirtyState = new EventEmitter<boolean>();
  @Output() removeCommentaire = new EventEmitter<TicketDetail>();

  public allIcons = svgIcons;
  public editMode: boolean = false;
  private itemBackup: TicketDetail = new TicketDetail();
  private ticketDetailDateBackup: Date | undefined = new Date();

  public activityCodeFilterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  public technicianFilterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  public invoiceCategoryFilterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  /**
   * Microfonction qui indique si les heures travaillées sont égales aux heures facturables...permettra de griser les heures facturables...
   */
  public isHoursEqual(): boolean {
    return this.ticketDetail.billableWorkedHours == this.ticketDetail.realWorkedHours;
  }

  /**
   * Microfonction qui indique si les déplacements réel sont égales aux déplacements facturables...permettra de griser les déplacements facturables...
   */
  public isDeplacementEqual(): boolean {
    return this.ticketDetail.billableTravelledHours == this.ticketDetail.realTravelledHours;
  }

  /**On note la valeur des heures travaillées précédentes, pour "cloner" les hrs facturables quand c'est identiques.. */
  private previousRealWorkedHours: number = 0;
  private previousRealTravelledHours: number = 0;

  ngOnDestroy() {
    this.unsubNotifier.next();
    this.unsubNotifier.unsubscribe();
  }

  constructor() {
    this.ticketDetail = new TicketDetail();
  }

  ngOnInit(): void {
    if (this.isNewTicketDetailMode) {
      this.editMode = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //on récupère le nom de la propriété ticketDetail dans THIS (this étant le composant au complet...)
    const ticketDetailPropertyName = this.utilService.nameof(this, (p) => p.ticketDetail);

    //si un changement est détecté sur le ticketDetail...
    if (changes[ticketDetailPropertyName] && !this.ticketDetail.ticketId) {
    }

    // lock la grille quand on ajoute un nouveau commentaire
    if (changes['isNewTicketDetailMode']?.currentValue) {
      this.updateEditFormDirtyState.emit(true);
    }
  }

  edit() {
    // cloner this.ticketDetail
    let ticketDetail = { ...this.ticketDetail };
    // force le format de date objet au lieu de string
    let myDate = this.ticketDetail.ticketDetailDate;
    ticketDetail.ticketDetailDate = this.utilService.formatStringToDate(myDate?.toString() ?? '');
    // open popup
    this.ticketDetailService.popupEdit(ticketDetail).subscribe((response: any) => {
      if (response !== false) {
        // refresh current comment
        this.ticketDetail = response;
        // refresh list feuille de temps
        this.refreshEditList.emit();
        this.refreshFdtList.emit();
        this.refreshTicketHeader.emit();
      }
    });
  }
}
