import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationService } from '@progress/kendo-angular-notification';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { CompositeFilterDescriptor, FilterDescriptor, State, toODataString } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';
import { TranslationManagementService } from '../core/translation/translate.service';

import { PopAlertComponent } from './component/pop-alert/pop-alert.component';
import { PopConfirmComponent } from './component/pop-confirm/pop-confirm.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private tmService: TranslationManagementService
  ) {}

  // FORMAT DATE

  /*
   * retourne la date en format YYYY-MM-DDTHH:MM:SS.000Z
   * @param value : date à formater
   */
  formatDateToString(value: Date): string {
    var date = value;
    var strDate =
      date.getFullYear() +
      '-' +
      this.pad(date.getMonth() + 1, 2) +
      '-' +
      this.pad(date.getDate(), 2) +
      'T' +
      this.pad(date.getHours(), 2) +
      ':' +
      this.pad(date.getMinutes(), 2) +
      ':' +
      this.pad(date.getSeconds(), 2) +
      '.00';
    return strDate;
  }

  /**
   * Cette méthode prendra une date en STRING, et va retourner le MINUIT, NON pas UTC, maais dans NOTRE fuseau horaire,
   * @param value
   * @returns
   */
  formatStringToDate(value: string) {
    if (value) {
      var date = new Date();
      var y = +value.substring(0, 4);
      var m = +value.substring(5, 7) - 1;
      var d = +value.substring(8, 10);
      date = new Date(y, m, d);
      return date;
    } else return new Date(0);
  }

  /**
 * Cette méthode prendra une date en STRING, et va retourner le MINUIT, NON pas UTC, maais dans NOTRE fuseau horaire,
 * @param value
 * @returns
 */
  formatStringToDateTime(value: string) {
    if (value) {
      let date = new Date();
      let y = +value.substring(0, 4);
      let m = +value.substring(5, 7) - 1;
      let d = +value.substring(8, 10);
      let h = +value.substring(11, 13);
      let min = +value.substring(14, 16);
      let sec = +value.substring(17, 19);

      date = new Date(y, m, d, h, min, sec);
      return date;
    } else return new Date(0);
  }

  // DATE: TROUVER JOUR DE DEBUT ET FIN DE SEMAINE

  /**
   * Retourne le premier et le dernier jour de la semaine (dimanche à samedi) pour une date donnée dans le format [YYYY-MM-DD]
   * @param date
   * @returns
   */
  getStartEndOfWeek(date: Date): [any, any] {
    var start = new Date(date);
    var end = new Date(date);
    var day = date.getDay();
    start.setDate(date.getDate() - day);
    end.setDate(date.getDate() - day + 6);
    return [this.fixDate(start), this.fixDate(end)];
  }

  /**
   * Cette fonction retourne la date en format YYYY-MM-DD
   * (corrige le bug de la date qui est 1 jour en avance selon le fuseau horaire)
   * @param date
   * @returns
   */
  fixDate(date: Date) {
    if (date == null) return '';
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
  }

  /**
   * Converti une date en filtre de date format ISO-8601
   * soit en ajoutant T00:00:00.000Z pour le début de la journée
   * ou en ajoutant T23:59:59.999Z pour la fin de la journée
   * @param date date a convertir
   * @returns
   */
  convertToDateFilter(date: string, operator: string = 'eq') {
    if (operator == 'le') {
      return date + 'T23:59:59.999Z';
    } else {
      return date + 'T00:00:00.000Z';
    }
  }

  /*
   * Arrondi un nombre au quart de décimal le plus proche (.25, .50, .75, .00)
   */
  roundToQuarterDecimal(value: number): number {
    return Math.round(value * 4) / 4;
  }

  /*
   * Arrondi un nombre au quart de décimal SUIVANT (donc round UP forcé)
   */
  roundToNextQuarterDecimal(value: number): number {
    return Math.ceil(value * 4) / 4;
  }

  /*
   * ajoute des zéros devant un nombre pour qu'il ait une longueur donnée
   * @param num : nombre à formater
   * @param size : longueur souhaitée
   */
  pad(num: number, size: number) {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }

  /*
   * Retire les caractères spéciaux d'une chaîne de caractères (Emojie, météo,  petite étoile, etc...)
   */
  removeSpecialCharacters(text: string | undefined) {
    //si nulle..bah on retourne tout de suite (nulle ou undefined..)
    if (!text) return text;

    // Expression régulière pour filtrer les caractères spéciaux
    const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]/g;
    return text!.replace(regex, '');
  }

  // NOTIFICATION

  notify(key: string, typeStyle: any, hideAfterMS: number = 3000) {
    let content = this.translateService.instant(key);
    this.notificationService.show({
      content: content,
      cssClass: 'si-notification',
      animation: { type: 'slide', duration: 300 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: typeStyle, icon: true }, // style = 'none'|'success'|'error'|'warning'|'info'
      hideAfter: hideAfterMS,
    });
  }

  // DIALOG | ALERT

  private dialog!: DialogRef;

  alert(title: string, message: string, cssClass: string | null = null) {
    // open dialog
    this.dialog = this.dialogService.open({
      content: PopAlertComponent,
      minWidth: 400,
      maxWidth: 700,
      cssClass: cssClass,
    });
    // set default values
    const dialogData = this.dialog.content.instance as PopAlertComponent;
    dialogData.title = title;
    dialogData.message = message;
    // subscribe to response
    this.dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        // cancel
        return true;
      } else {
        // submit
        return false;
      }
    });
  }

  // DIALOG | CONFIRM

  public confirm(title: string, message: string, yes: string, no: string): any {
    const confirmObservable = new Observable((observer) => {
      // open dialog
      this.dialog = this.dialogService.open({
        content: PopConfirmComponent,
        minWidth: 400,
        maxWidth: 700,
      });
      // set default values
      const dialogData = this.dialog.content.instance as PopConfirmComponent;
      dialogData.title = title;
      dialogData.message = message;
      dialogData.button_yes = yes;
      dialogData.button_no = no;
      // subscribe to response
      this.dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          // cancel
          observer.next(false);
        } else {
          // confirm
          observer.next(true);
        }
      });
    });
    return confirmObservable;
  }

  public alertAwaitable(title: string, message: string): any {
    const confirmObservable = new Observable((observer) => {
      // open dialog
      this.dialog = this.dialogService.open({
        content: PopAlertComponent,
        minWidth: 400,
      });
      // set default values
      const dialogData = this.dialog.content.instance as PopAlertComponent;
      dialogData.title = title;
      dialogData.message = message;
      this.dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          // cancel
          observer.next(false);
        } else {
          // confirm
          observer.next(true);
        }
      });
    });

    return confirmObservable;
  }

  // PASSWORD VALIDATION

  validatePassword(newPassword: string, confirmPassword: string, oldPassword: string, modifySelf: boolean): string {
    if (modifySelf && oldPassword == '') return 'module.auth.reset-password.error_confirm_old_password';
    else if (newPassword.length < 8) return 'module.auth.reset-password.error_too_short';
    else if (newPassword != confirmPassword) return 'module.auth.reset-password.error_not_the_same';
    else if (/[A-Z]/.test(newPassword) == false) return 'module.auth.reset-password.error_uppercase';
    else if (/[a-z]/.test(newPassword) == false) return 'module.auth.reset-password.error_lowercase';
    else if (/[0-9]/.test(newPassword) == false) return 'module.auth.reset-password.error_number';
    else if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPassword) == false) return 'module.auth.reset-password.error_special_char';
    else return '';
  }

  public validateEmail(email: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) return true;
    else return false;
  }

  private makeCompositeFilterDescriptorCaseInsensitive(value: CompositeFilterDescriptor): CompositeFilterDescriptor {
    let _filter = value.filters;
    for (const filter of _filter) {
      this.filterRecursive(filter);
    }
    return {
      logic: value.logic,
      filters: _filter,
    };
  }
  /**
   * Permet de creuser récursivement pour que les FilterDescriptor au
   * sein du ou des CompositeFilterDescriptor se font rajouter le case insensitivity
   * @param filter
   */
  private filterRecursive(filter: CompositeFilterDescriptor | FilterDescriptor): void {
    const caseSensitiveOperators: string[] = ['contains', 'eq', 'neq', 'startswith', 'endswith', 'doesnotcontain'];
    // Certain champs de type enum saute et on doit donc les exclures
    let fieldListToAvoid = ['erpSystemType', 'assignedPrivilegeCode'];

    if (this.instanceOfFilterDescriptor(filter)) {
      let field = `${filter.field}`;
      if (caseSensitiveOperators.indexOf(filter.operator.toString()) !== -1) {
        if (field && fieldListToAvoid.indexOf(field) > -1) {
          filter.ignoreCase = false;
        } else {
          filter.ignoreCase = true;
          if (typeof filter.value === 'string') {
            filter.value = filter.value.toLowerCase();
          }
        }
      }
    } else {
      for (const subFilter of filter.filters) {
        this.filterRecursive(subFilter);
      }
    }
  }

  /*Permet de savoir si un objet qui hérite de l'interface FilterDescriptor*/
  private instanceOfFilterDescriptor(_obj: FilterDescriptor | CompositeFilterDescriptor): _obj is FilterDescriptor {
    return 'field' in _obj;
  }

  public getODataFullString(state: State, oDataString: string) {
    // merge oDataString Filter and grid Filter, remove from state
    let newState: State = {
      take: state.take,
      skip: state.skip,
      sort: state.sort,
      group: state.group,
    };

    let filterStr: string = '';

    // S'il y a un ou des filtres d'appliqués alors on les rend case insensitive
    if (state.filter) {
      filterStr = toODataString({
        filter: this.makeCompositeFilterDescriptorCaseInsensitive(state.filter),
      });
    } else {
      filterStr = toODataString({ filter: state.filter });
    }
    // Corrige un edge case où la requête formée pouvait avoir plusieurs filter statement.
    if (filterStr) {
      filterStr = filterStr.replace('$filter=', '');
      filterStr = filterStr.replace(/T00:00:00.000Z/g, '');
      if (oDataString) {
        if (oDataString.indexOf('$filter') != -1) {
          oDataString += ' and ' + filterStr;
        } else {
          oDataString += '&$filter=' + filterStr;
        }
      } else {
        oDataString = '&$filter=' + filterStr;
      }
    }
    let data = oDataString + '&' + toODataString(newState);
    return data;
  }

  public makeStateCaseInsensitive(state: State): State {
    let _state = state;
    if (state.filter) {
      _state.filter = this.makeCompositeFilterDescriptorCaseInsensitive(state.filter);
    }

    return state;
  }

  public buildMultiSelectFilter(values: any[], fieldName: string, idPropertyName: string): any {
    if (values && values.length > 0) {
      var newFilter: any[] = [];
      values.forEach((e) => {
        newFilter.push({
          field: fieldName,
          operator: 'eq',
          value: e[idPropertyName] == -1 ? null : e[idPropertyName],
        });
      });
      var filterOr = { filters: newFilter, logic: 'or' };
      return filterOr;
    }
    return false;
  }

  /**
   * Permet d'écrire au Console LOG soit si on est PAS en prod, ou si on force l'écriture...
   * @param message
   */
  public logInformation(messageToWrite: string, forceWrite: boolean = false) {
    if (!environment.production || forceWrite) {
      const formattedTime = new Date().toISOString().slice(11, -1);
      console.log(`${formattedTime} - ${messageToWrite}`);
    }
  }

  /*
   * Permet de retourner un paramètre de l'URL
   */
  public getUrlParameter(parameterName: string) {
    var result = null,
      tmp = [];
    location.search
      .substr(1)
      .split('&')
      .forEach(function (item) {
        tmp = item.split('=');
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  }

  /**
   * Cette fonction imitte le comportement de nameof en C#.  Permet donc de retourner le nom de la propriété d'un objet­. Évite les magics string dangereux qui peuvent causer des erreurs lors de refactoring.
   *
   * Usage : let MonNomDeProp = nameof(this, p => p.MaPropriété);
   */
  nameof<T>(obj: T, value: (obj: T) => any): string {
    const p = value(obj);
    for (const prop in obj) {
      if (obj[prop] === p) {
        return prop;
      }
    }
    return '';
  }

  /*
   * Copie le texte dans le presse-papier
   */
  copyToClipboard(text: string = '') {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}
