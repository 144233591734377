import { Pipe, PipeTransform } from '@angular/core';
import { TicketHeader } from 'src/app/module/service/ticket/ticket-header/ticket-header.model';
/**
 * Retourne information sur le Ticket passé
 * Usage:
 *   myLine.TicketId  | getTicketHeaderInfo : UneListeDeTicketPreRemplit
 * Example:
 */
@Pipe({
  name: 'getTicketHeaderInfo',
})
export class TicketHeaderInfoPipe implements PipeTransform {
  transform(value: any, ticketList: TicketHeader[]): TicketHeader {
    let myEmptyTicket = new TicketHeader();
    myEmptyTicket.ticketId = value;

    if (!ticketList) {
      myEmptyTicket.scTicketShortTitle = 'TicketPipeErr -> TicketList vidE!';
      return myEmptyTicket;
    }

    //si le type de valeur passée n'est pas un chiffre..
    if (typeof value != 'number') {
      myEmptyTicket.scTicketShortTitle = 'TicketPipeErr -> TicketId invalid (nan)!';
      return myEmptyTicket;
    }

    let ticketId = value;

    // find my TicketHeader name
    if (ticketId == 0) {
      myEmptyTicket.scTicketShortTitle = 'TicketPipeErr -> TicketId invalid (=0)';
      return myEmptyTicket;
    }

    let myTicket = ticketList.filter((c: TicketHeader) => c.ticketId == ticketId);
    if (myTicket && myTicket.length > 0) {
      return myTicket[0];
    } else {
      myEmptyTicket.scTicketShortTitle = "TicketPipeErr -> Vous n'avez plus accès à ce ticket!";
      return myEmptyTicket;
    }
  }
}
