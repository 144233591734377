import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EditorPasteEvent } from '@progress/kendo-angular-editor';
import { Observable, catchError, Subscription, takeUntil, Subject, tap, take, map, switchMap, from, forkJoin, firstValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class FileManagerService {
  constructor(private http: HttpClient, private utilService: UtilService) {}

  /**
   * Cette fonction permet de coller une image dans un éditeur de texte et de vérifier si l'image est trop lourde
   * Ça pop une erreur si l'image est trop lourde et annule l'ajout de l'image dans le texte.
   * @param event événement de collage
   * @returns true si l'événement a été traité
   */
  public pasteEventImageHandler(event: EditorPasteEvent): void {
    // on veut vérifier si ça colle une image
    if (event.cleanedHtml && event.cleanedHtml.includes('data:image')) {
      // si l'image est trop lourde on annule l'événement
      if (event.cleanedHtml.length > 500000) {
        event.preventDefault();
        this.utilService.alert('error.FILE_IMAGE_EXCEED_LIMIT_TITLE', 'error.FILE_IMAGE_EXCEED_LIMIT_500KB');
      }
    }
  }

  /*
   * Cette fonction permet de préparer un texte pour l'envoyer au serveur
   * Elle remplace les images en base64 par des codes temporaires
   * Elle retourne le texte et la liste des images en base64
   */
  prepareFieldWithImagesToUpload(texte: string | undefined, imageList: any): any {
    let texteNotNull = texte ?? ''; // si le texte est null on le remplace par une chaine vide
    if (texte == null || texte == undefined) return { texte: '', imageList: [] };

    // etape 1 : trouver toutes les images a uploader

    let parser = new DOMParser();
    let htmlDocument = parser.parseFromString(texte, 'text/html');
    let htmlImages = htmlDocument.querySelectorAll('img');
    // trouve toutes les images
    let ImgArray = Array.from(htmlImages);
    // garde seulement les images qui n'ont pas été uploadées
    let imagesInBase64 = ImgArray.filter((img) => img.src.startsWith('data:image'));

    // etape 2 : remplacer les images du texte avec les code temporaire

    let cnt = 0; // nécessaire pour que le ID soit unique
    imagesInBase64.forEach((img) => {
      let fileId = '{{IMAGE_' + imageList.length + '}}';
      // on remplace par un code temporaire
      texteNotNull = texteNotNull.replace(img.src, fileId);
      // on ajoute l'image à la liste
      imageList.push({ fileId: fileId, imgSrc: img.src, url: '' });
    });

    // etape 3 : retourner le texte et les images

    return {
      texte: texteNotNull,
      imageList: imageList,
    };
  }

  /*
   * Remplace les codes temporaires par les urls des images
   */
  public replaceTemporaryCodeByCdnUrl(texte: string, imageList: any[]) {
    imageList.forEach((image) => {
      texte = texte.replace(image.fileId, image.url);
    });
    return texte;
  }

  /*
   * Cette fonction upload toutes les images
   * Retourne la liste d'images avec l'url de l'image mise à jour
   */
  async uploadAllImages(folder: string, index: any, images: any[]): Promise<string[]> {
    // loop all images
    for (const image of images) {
      // upload one file
      let response = await firstValueFrom(this.uploadImage(folder, index, image.imgSrc));
      // notify (mis ici pour pouvoir afficher la progression. Exemple: fichier 2/3)
      if (response === 'error') {
        this.utilService.notify('shared.notification.error_file_upload', 'error');
      } else {
        this.utilService.notify('shared.notification.success_file_upload', 'success');
      }
      // update imageList
      image.url = response;
    }
    return images;
  }

  /*
   * envoie une image sur le serveur et retourne l'url de l'image
   */
  uploadImage(folder: string, index: any, imageSrc: any): Observable<string> {
    let uri = `${apiUrl}FileManager/UploadImage`;

    // On crée un fichier à partir du base64
    let file = this.convertBase64DataSrcToFile(imageSrc);

    // preparation du data
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('folder', folder);
    formData.append('index', index);

    return this.http.post<string>(uri, formData).pipe(
      map((response: any) => {
        return response.url;
      }),
      catchError((error) => 'error')
    );
  }

  /**
   * Convertit une image en base 64 en une instance de File
   * @param srcAttributeValue valeur de l'attribut src de l'image Base64
   * @param imageName nom du fichier image, (ne pas mettre l'extention, elle est déduite de la valeur de srcAttributeValue)
   * @returns le fichier image
   */
  public convertBase64DataSrcToFile(srcAttributeValue: string): File {
    let imageName = '' + new Date().getTime();

    let arr = srcAttributeValue.split(',');
    // la regex nous retroune le mime type de l'image (son extention) dans la seconde position de l'array
    let mime = (arr[0].match(/:(.*?);/) ?? '')[1];

    // conversion base64 vers bytes
    let bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;

    // transformer les bytes en tableau de bytes (Uint8Array)
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    // on va chercher l'extention de l'image
    let extension = mime.split('/')[1];
    let name = `${imageName}.${extension}`;

    // et voilà on a notre fichier
    let file = new File([u8arr], name, { type: mime });

    return file;
  }
}
