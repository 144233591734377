/**
 * Liste des clé de permission, trouvé dans les claims
 */
export enum permissionCodeEnum {
  // MODULES ACCOUNT RECEIVABLE
  AR__CUSTOMER__CAN_LIST_SIMPLE = 'AR__CUSTOMER__CAN_LIST_SIMPLE',
  AR__CUSTOMER__CAN_LIST_FULL = 'AR__CUSTOMER__CAN_LIST_FULL',
  AR__CUSTOMER__CAN_MODIFY = 'AR__CUSTOMER__CAN_MODIFY',
  AR__CUSTOMER__CAN_CREATE = 'AR__CUSTOMER__CAN_CREATE',

  AR__INVOICES__CAN_LIST_SIMPLE = 'AR__INVOICES__CAN_LIST_SIMPLE',
  AR__INVOICES__CAN_LIST_FULL = 'AR__INVOICES__CAN_LIST_FULL',
  AR__INVOICES__CAN_PAY_INVOICE = 'AR__INVOICES__CAN_PAY_INVOICE',

  AR__SERVICE_CONTRACT__CAN_LIST = 'AR__SERVICE_CONTRACT__CAN_LIST',

  // MODULES SERVICE
  SERVICES__TICKETS__CAN_SEE_TICKETS = 'SERVICES__TICKETS__CAN_SEE_TICKETS',
  SERVICES__TICKETS__CAN_CREATE_TICKETS = 'SERVICES__TICKETS__CAN_CREATE_TICKETS',
  SERVICES__TICKETS__CAN_MODIFY_TICKETS = 'SERVICES__TICKETS__CAN_MODIFY_TICKETS',
  SERVICES__TICKETS__CAN_ADD_TIME = 'SERVICES__TICKETS__CAN_ADD_TIME',
  SERVICES__TICKETS__CAN_CLOSE_TICKETS = 'SERVICES__TICKETS__CAN_CLOSE_TICKETS',

  // MODULES SOFTWARE MANAGER
  SOFTMNGR__CUSTLICENCE__CAN_LIST_SIMPLE = 'SOFTMNGR__CUSTLICENCE__CAN_LIST_SIMPLE',
  SOFTMNGR__CUSTLICENCE__CAN_LIST_FULL = 'SOFTMNGR__CUSTLICENCE__CAN_LIST_FULL',
  SOFTMNGR__CUSTLICENCE__CAN_CREATE = 'SOFTMNGR__CUSTLICENCE__CAN_CREATE',
  SOFTMNGR__CUSTLICENCE__CAN_MODIFY_BASE = 'SOFTMNGR__CUSTLICENCE__CAN_MODIFY_BASE',
  SOFTMNGR__CUSTLICENCE__CAN_MODIFY_FULL = 'SOFTMNGR__CUSTLICENCE__CAN_MODIFY_FULL',

  SOFTMNGR__SOFTLOGS__CAN_LIST = 'SOFTMNGR__SOFTLOGS__CAN_LIST',

  // MODULES SECURITY
  SECURITY__USERS__CAN_LIST_SIMPLE = 'SECURITY__USERS__CAN_LIST_SIMPLE',
  SECURITY__USERS__CAN_LIST_FULL = 'SECURITY__USERS__CAN_LIST_FULL',
  SECURITY__USERS__CAN_MODIFY = 'SECURITY__USERS__CAN_MODIFY',
  SECURITY__USERS__CAN_MANAGE_SECURITY = 'SECURITY__USERS__CAN_MANAGE_SECURITY',
  SECURITY__USERS__CAN_CREATE = 'SECURITY__USERS__CAN_CREATE',
  SECURITY__USERS__CANBROWSEASANOTHERUSER = 'SECURITY__USERS__CANBROWSEASANOTHERUSER',

  SYSTEM__SYSTEM__ADMIN = 'SYSTEM__SYSTEM__ADMIN',

  PRIVILEGE_INVALIDE_CODE = 'PRIVILEGE_INVALIDE_CODE',
}

/**
 * Cet enum contient des morceaux de l'encodage des claims de permission.
 * exemple d'un claims :  "PRIVILEGE_ORDER_CANVIEWORDERHISTORY": ["|SOURCE:AssignedByRole|ROLE:SUPERVISEUR|CUSTID:105302|UGID:210|AUID:|"]
 *    --> on peut voir que le claim contient des morceaux de permission, séparé par des pipes (|).  la source de ce claim est via un role, le nom du role est 'superviseur', pour le customer 105302,  sur le userGroup 210,  et aucun userID spécifié
 */
export enum permissionPartCodeEnum {
  SOURCE = 'SOURCE', //Source de l'assignation [AssignedByRole, DirectAssign]
  ROLE = 'ROLE', //le role de l'assignation
  CUSTID = 'CUSTID', //le customerID
  UGID = 'UGID', //userGroupID
  AUID = 'AUID', //assigned userID
}
