import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable, of } from 'rxjs';
import { mergeMap, tap, map, switchMap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State, toODataString } from '@progress/kendo-data-query';

import { Department } from './department.model';
import { UtilService } from 'src/app/shared/util.service';
import { LocalStorage } from 'src/app/core/local-storage.enum';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  private departments: ReplaySubject<Department[]> = new ReplaySubject<Department[]>(1);
  public department: ReplaySubject<Department> = new ReplaySubject<Department>(1);
  public departmentsOdata: ReplaySubject<any> = new ReplaySubject<any>(1);
  private departmentList: Department[] = []; // liste des departments dans la cache

  constructor(private http: HttpClient, private utilService: UtilService) {}

  list(): Observable<Department[]> {
    let uri = `${apiUrl}services/Department/GetListRaw`;
    return this.http.get<Department[]>(uri).pipe(
      mergeMap((response: any) => {
        let value = response.value;
        this.departments.next(value);
        return of(value);
      })
    );
  }

  // CACHE

  /**
   * cette fonction retourne tous les records mis en cache dans le localStorage.
   * si la cache n'a pas été rafraichie depuis 20 secondes, on la rafraichi.
   * on fetch les données de l'API de facon recursive pour obtenir tous les records.
   * @param forceFetch
   * @returns la liste de tous les records
   */

  listCache(forceFetch?: boolean): Observable<Department[]> {
    let fetch = false;
    // read localStorage
    let ls = localStorage.getItem(LocalStorage.CACHE_DEPARTMENT);
    if (forceFetch) {
      fetch = true;
    } else if (ls != null && ls.length > 0) {
      let data = JSON.parse(ls!);
      let now = Date.now();
      // force to refresh every X seconds
      if (now - data.lastUpdate > 20000) {
        fetch = true;
      }
    } else {
      // empty localstorage
      fetch = true;
    }
    // fetch or return cache data
    if (fetch) {
      // reset cache et start fetching
      localStorage.removeItem(LocalStorage.CACHE_DEPARTMENT);
      return this.fetchOData(apiUrl + 'services/Department?$select=departmentId,departmentShortCode,nameFr,nameEn');
    } else {
      let data = JSON.parse(ls!);
      // update service variable
      this.departmentList = data.data;
      return of(data.data);
    }
  }

  /*
   * cette fonction fetch les données de l'API de facon recursive pour obtenir tous les records.
   */
  private fetchOData(url: string): Observable<Department[]> {
    let uri = `${url}`;
    return this.http.get<any>(uri).pipe(
      switchMap((response) => {
        const data = response.value as Department[];
        // fetch actual cache & update it
        let existingCache = JSON.parse(localStorage.getItem(LocalStorage.CACHE_DEPARTMENT)!) || { lastUpdate: 0, data: [] };
        existingCache.data = existingCache.data.concat(data);
        existingCache.lastUpdate = Date.now();
        localStorage.setItem(LocalStorage.CACHE_DEPARTMENT, JSON.stringify(existingCache));
        // update service variable
        this.departmentList = existingCache.data;
        // fetch next page if needed OR return data
        if (response['@odata.nextLink']) {
          // ATTENTION!!! ce endpoint n'est pas oDATA alors cette condition ne s'appliquera jamais
          return this.fetchOData(response['@odata.nextLink']).pipe(map((nextData) => data.concat(nextData)));
        } else {
          return of(data);
        }
      }),
      catchError((error) => {
        console.error('Error fetching data:', error);
        return of([]);
      })
    );
  }

  // ODATA : STATE

  odata(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${apiUrl}services/Department?${toODataString(_state)}&$count=true`;

    return this.http.get<Department>(uri).pipe(
      tap((response: any) => {
        this.departmentsOdata.next(response);
      })
    );
  }

  // ODATA  : STRING

  odataStr(state: State, oDataString: string): Observable<any> {
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    let uri = `${apiUrl}services/Department?${oDataFullString}&$count=true`;
    return this.http.get<any>(uri);
  }
}
