/**Représente un technician, ou "usager" dans servicentre.. */
export class Technician {
  public TechnicianCode: string = '';

  public Email?: string = '';

  public IsActive?: boolean = false;

  public FullName: string = '';
}
