import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { KendoModule } from './kendo.module';

// generic

import { TimeElapsedPipe } from './pipe/time-elapsed.pipe';
import { DisplayIfNotZeroPipe } from './pipe/display-nothing-if-zero.pipe';

import { PopAlertComponent } from './component/pop-alert/pop-alert.component';
import { PopConfirmComponent } from './component/pop-confirm/pop-confirm.component';
import { LoaderComponent } from './component/loader/loader.component';

// module specific

import { CustomerNamePipe } from './pipe/customer-name.pipe';
import { TechnicianNamePipe } from './pipe/technician-name.pipe';
import { TicketHeaderInfoPipe } from './pipe/tickets/ticket-header-info.pipe';
import { DepartmentNamePipe } from './pipe/department-name.pipe';
import { ActivityCodePipe } from './pipe/activity-code.pipe';

import { TicketHeaderEditComponent } from '../module/service/ticket/ticket-header/ticket-header-edit/ticket-header-edit.component';
import { TicketDetailEditPopupComponent } from '../module/service/ticket/ticket-detail/ticket-detail-edit-popup/ticket-detail-edit-popup.component';
import { TicketDetailViewComponent } from '../module/service/ticket/ticket-detail/ticket-detail-view/ticket-detail-view.component';
import { ServiceContractPopupComponent } from '../module/account-receivable/service-contract/service-contract-popup/service-contract-popup.component';

@NgModule({
  declarations: [
    PopAlertComponent,
    PopConfirmComponent,
    LoaderComponent,

    /*NOS PIPES */
    TimeElapsedPipe,
    DisplayIfNotZeroPipe,
    CustomerNamePipe,
    TechnicianNamePipe,
    TicketHeaderInfoPipe,
    DepartmentNamePipe,
    ActivityCodePipe,

    TicketHeaderEditComponent,
    TicketDetailEditPopupComponent,
    TicketDetailViewComponent,
    ServiceContractPopupComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule, RouterModule, KendoModule, TranslateModule],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    KendoModule,
    TranslateModule,
    LoaderComponent,

    /*NOS PIPES */
    TimeElapsedPipe,
    DisplayIfNotZeroPipe,

    CustomerNamePipe,
    DepartmentNamePipe,
    TechnicianNamePipe,
    TicketHeaderInfoPipe,
    ActivityCodePipe,

    TicketHeaderEditComponent,
    TicketDetailEditPopupComponent,
    TicketDetailViewComponent,
    ServiceContractPopupComponent,
  ],
})
export class SharedModule {}
