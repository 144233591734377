import { Injectable, inject } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { State, isCompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Location } from '@angular/common';

import { LocalStorage } from 'src/app/core/local-storage.enum';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class GridService {
  private utilService = inject(UtilService);
  private location = inject(Location);

  public pageSizes = [10, 25, 50, 100];
  public take = 25;
  public selectableSettings: SelectableSettings = {
    enabled: true,
    mode: 'single',
  };

  constructor() {}

  setRowPerPages(state: any) {
    this.take = state.take;
    localStorage.setItem(LocalStorage.GRID_ITEM_PER_PAGE, state.take + '');
  }

  /**
   *
   * @param state cette fonction ajoute le filtre de recherche dans le state ou le met à jour ou l'enleve si vide
   * @param fieldName nom du champ à filtrer dans le odata query string
   * @param value valeur du champ à filtrer dans le odata query string
   * @returns
   */
  applyFilterToState(state: State, fieldName: string, value: any, operator: string = 'eq'): State {
    // init state filters if empty
    if (!state.filter) {
      state.filter = { logic: 'and', filters: [] };
    }
    // find filter
    let filters = state.filter.filters;
    let fieldsFound = filters.filter((x: any) => x.field === fieldName);

    //ici, on vérifie si la valeur du filtre est vide, null ou undefined.  ça veut dire qu'on va RETIRER ce filtre du state.
    //pourquoi?  c'est quand un usager sélectionne un élément 'VIDE' d'un drop-downlist (ex: 'Tous les statuts' ou 'Tous les types')
    let isFilterRemovalValue = value === '' || value === undefined || value === null;

    if (isFilterRemovalValue && fieldsFound.length > 0) {
      // remove filter
      filters = filters.filter((x: any) => x.field !== fieldName);
    } else if (fieldsFound.length > 0) {
      const filter = fieldsFound[0];
      // update filter
      if ('value' in filter) {
        filter.value = value;
      }
    } else {
      // add filter
      filters.push({ field: fieldName, operator: operator, value });
    }
    // update state and refresh
    state.filter.filters = filters;
    return state;
  }

  /*
   * Cette fonction met a jour l'url dans la barre d'adresse avec les filtres actifs dans le state
   */
  refreshAddressBarWithStateFilter(state: State): void {
    let urlParam = '';
    // find filter
    let filters = state?.filter?.filters;
    if (filters) {
      filters.forEach((filter) => {
        // ne supporte pas les filtre multi level
        if (!isCompositeFilterDescriptor(filter)) {
          let fieldName = filter.field as string;
          if (fieldName) {
            if (typeof filter.value.getMonth === 'function') {
              // date value
              let dateStr = this.utilService.formatDateToString(filter.value);
              urlParam += `&${filter.field}=${dateStr}`;
            } else {
              // other values
              urlParam += `&${fieldName}=${filter.value}`;
            }
          }
        }
      });
      // enlever le premier & et le remplacer par ?
      urlParam = urlParam.replace('&', '?');
      // update address bar
      let url = this.location.path().split('?')[0] + urlParam;
      this.location.replaceState(url);
    }
  }

  /*
   * Cette fonction met a jour le state avec des comparaisons de date qui fonctionne avec l'API ODATA
   */
  fixStateFilterDate(fieldName: string, state: State): State {
    // cloner le state
    let _state = structuredClone(state);
    if (_state.filter?.filters) {
      // find filtre date dans le state
      let filterDate = _state.filter?.filters.find((x: any) => x.field === fieldName);
      if (filterDate) {
        // get date value
        let dateValue = (filterDate as any).value;
        let operator = (filterDate as any).operator;
        if (operator === 'eq') {
          // enlever le filtre date du state
          const filterToRemoveIndex = _state.filter.filters.findIndex((filter: any) => filter.field === fieldName);
          if (filterToRemoveIndex !== -1) {
            _state.filter.filters.splice(filterToRemoveIndex, 1);
          }
          // preparer la comparaison de date
          let startDate = dateValue.setHours(0, 0, 0, 0);
          let endDate = dateValue.setHours(23, 59, 59, 999);
          // ajouter filtre de recherche date
          _state.filter.filters.push({ field: fieldName, operator: 'ge', value: new Date(startDate) });
          _state.filter.filters.push({ field: fieldName, operator: 'le', value: new Date(endDate) });
        }
      }
    }
    return _state;
  }

  /**
   * cette fonction sauvegarde le state et les config colonnesde la grille dans le local storage
   * @param preset
   * @param key
   */
  saveGridPreset(preset: any, key?: string) {
    localStorage.setItem(LocalStorage.GRID_STATE_TICKET_LIST, JSON.stringify(preset));
    this.utilService.notify('shared.notification.success_save_preference', 'success');
  }

  /**
   * cette fonction retourne le preset sauvegardé dans le local storage
   * @param preset
   * @param key
   */
  loadGridPreset(key?: string): any {
    let presetJson = localStorage.getItem(LocalStorage.GRID_STATE_TICKET_LIST);
    if (presetJson) {
      return JSON.parse(presetJson);
    } else {
      return null;
    }
  }
}
