import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable, of } from 'rxjs';
import { mergeMap, tap, map, switchMap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State, toODataString } from '@progress/kendo-data-query';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';

import { Customer } from './customer.model';
import { CustomerPopupComponent } from './customer-popup/customer-popup.component';
import { UtilService } from 'src/app/shared/util.service';
import { LocalStorage } from 'src/app/core/local-storage.enum';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private customers: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);
  public customer: ReplaySubject<Customer> = new ReplaySubject<Customer>(1);
  public customersOdata: ReplaySubject<any> = new ReplaySubject<any>(1);
  private customerList: Customer[] = []; // liste des clients dans la cache
  private dialog!: DialogRef;

  constructor(private http: HttpClient, private dialogService: DialogService, private utilService: UtilService) {}

  initOne() {
    this.customer.next(new Customer());
  }

  list(): Observable<Customer[]> {
    let uri = `${apiUrl}AR/Customers`;
    return this.http.get<Customer[]>(uri).pipe(
      mergeMap((response: any) => {
        let value = response.value;
        this.customers.next(value);
        return of(value);
      })
    );
  }

  read(id: number): Observable<Customer> {
    return this.http.get<Customer>(apiUrl + 'AR/Customers/' + id, {}).pipe(
      tap((response: any) => {
        this.customer.next(response);
      })
    );
  }

  create(customer: any): Observable<any> {
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    return this.http.post<any>(apiUrl + 'AR/Customers/', customer).pipe(
      tap((response: any) => {
        //this.customer.next(response); // TODO: réactiver quand l'API va me retourner le record comme dans un get
        // TODO : refresh de la variable this.customers avec le nouveau record
      })
    );
  }

  update(customer: any): Observable<any> {
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    return this.http.put<any>(apiUrl + 'AR/Customers/' + customer.customerId, customer).pipe(
      tap((response: any) => {
        //this.customer.next(response); // TODO: réactiver quand l'API va me retourner le record comme dans un get
      })
    );
  }

  // CACHE

  /**
   * cette fonction retourne la liste de client mis en cache dans le localStorage.
   * si  la cache n'A pas été rafraichie depuis 20 secondes, on la rafraichie.
   * on fetch les données de l'API de facon recursive pour obtenir tous les records.
   * @param forceFetch
   * @returns la liste de tous les clients
   */

  listCache(forceFetch?: boolean): Observable<Customer[]> {
    let fetch = false;
    // read localStorage
    let ls = localStorage.getItem(LocalStorage.CACHE_CUSTOMER);
    if (forceFetch) {
      fetch = true;
    } else if (ls != null && ls.length > 0) {
      let data = JSON.parse(ls!);
      let now = Date.now();
      // force to refresh every X seconds
      if (now - data.lastUpdate > 60000) {
        fetch = true;
      }
    } else {
      // empty localstorage
      fetch = true;
    }
    // fetch or return cache data
    if (fetch) {
      // reset cache et start fetching
      localStorage.removeItem(LocalStorage.CACHE_CUSTOMER);
      return this.fetchOData(apiUrl + 'AR/Customers?$select=noClient,nomClient,nomPersonneContact,telephone1,ville,adresse,estActif');
    } else {
      let data = JSON.parse(ls!);
      // update service variable
      this.customerList = data.data;
      return of(data.data);
    }
  }

  /*
   * cette fonction fetch les données de l'API de facon recursive pour obtenir tous les records.
   */
  private fetchOData(url: string): Observable<Customer[]> {
    let uri = `${url}`;
    return this.http.get<any>(uri).pipe(
      switchMap((response) => {
        const data = response.value as Customer[];
        // fetch actual cache & update it
        let existingCache = JSON.parse(localStorage.getItem(LocalStorage.CACHE_CUSTOMER)!) || { lastUpdate: 0, data: [] };
        existingCache.data = existingCache.data.concat(data);
        existingCache.lastUpdate = Date.now();
        localStorage.setItem(LocalStorage.CACHE_CUSTOMER, JSON.stringify(existingCache));
        // update service variable
        this.customerList = existingCache.data;
        // fetch next page if needed OR return data
        if (response['@odata.nextLink']) {
          return this.fetchOData(response['@odata.nextLink']).pipe(map((nextData) => data.concat(nextData)));
        } else {
          return of(data);
        }
      }),
      catchError((error) => {
        console.error('Error fetching data:', error);
        return of([]);
      })
    );
  }

  // ODATA : STATE

  odata(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${apiUrl}AR/Customers?${toODataString(_state)}&$count=true`;

    return this.http.get<Customer>(uri).pipe(
      tap((response: any) => {
        this.customersOdata.next(response);
      })
    );
  }

  // ODATA  : STRING

  odataStr(state: State, oDataString: string): Observable<any> {
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    let uri = `${apiUrl}AR/Customers?${oDataFullString}&$count=true`;
    return this.http.get<any>(uri);
  }

  // UPLOAD LOGO

  //api/AR/Customers/UploadCustomerLogo/59841
  uploadLogo(id: number, fileToUpload: File): Observable<any> {
    /*
    erreurs serveur possible:
    INVALID_FILE_CONTENTTYPE
    FILE_IMAGE_EXCEED_LIMIT_500KB
    */
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(apiUrl + 'AR/Customers/UploadCustomerLogo/' + id, formData).pipe(tap((response: any) => {}));
  }

  // POPUP CUSTOMER LIST

  public popup(): any {
    const confirmObservable = new Observable((observer) => {
      // open dialog
      this.dialog = this.dialogService.open({
        content: CustomerPopupComponent,
        minWidth: 400,
        height: 800,
        maxWidth: 900,
        cssClass: 'si-dialog-grid',
      });
      // set default values
      const dialogData = this.dialog.content.instance as CustomerPopupComponent;
      dialogData.title = 'module.customer.popup.title';
      dialogData.button_cancel = 'module.customer.popup.button_cancel';
      // subscribe to response
      this.dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          // cancel
          observer.next(false);
        } else {
          // confirm
          observer.next(result);
        }
      });
    });
    return confirmObservable;
  }

  /**
   * Cette fonction retourne le nom du client selon son numéro.
   * il faut s'assurere que la fonction listCache() a été appelée avant d'appeler cette fonction.
   * @param customerNo numéro du client
   * @returns le nom du client
   */
  public getCustomerName(customerNo: any): string {
    return customerNo + ' - ' + this.customerList.find((cl: any) => cl.noClient == customerNo)?.nomClient;
  }
}
