export class TicketHeader {
  public ticketId: number = 0;
  public scInvoicingCategoryCode: string = '';
  public scTicketType: string = '';
  public scTicketShortTitle: string = '';
  public scTicketStatusCode: string = '';
  public ticketStatusId: number = 0;
  public ticketTypeId: number = 0;
  public serviceContractId: number = 0;
  public openingDate: string = '';
  public closingDate: string = '';
  public expectedDate: string = '';
  public isClosed: boolean = false;
  public ticketLongTitle: string = '';
  public sC_Ticket_CreatedBy_UserCode: string = '';
  public sC_Ticket_AssignedTo_UserCode: string = '';
  public customerCode: string = '';
  public customerName: string = '';
  public customerContactName: string = '';
  public isFeesFixedAmount: boolean = false;
  public fixedFeeAmount: number = 0;
  public isProductFixedAmount: boolean = false;
  public fixedProductAmount: number = 0;
  public fixedTotalAmount: number = 0;
  public totalNbOfHoursSpent: number = 0;
  public scLocalisation: string = '';
  public scGlobalComment: string = '';
  public billableSubTotalAmount: number = 0;
  public departmentId: number = 0;
  public totalTravelledHours: number = 0;
  public totalWorkedHours: number = 0;
  constructor() {}
}
