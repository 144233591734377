import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslationManagementService } from 'src/app/core/translation/translate.service';

/**
 * Retourne le temps écoulé depuis la date spécifiée
 */
@Pipe({
  name: 'timeElapsed',
})
export class TimeElapsedPipe implements PipeTransform {
  private tmService = inject(TranslationManagementService);

  transform(paramOrigDate: any): string {
    try {
      //let date = new Date(dateStr);
      if (!paramOrigDate) return 'N/A';

      let now = new Date();
      let DateToTest = new Date(paramOrigDate);

      let diff = now.getTime() - DateToTest.getTime();
      let seconds = Math.floor(diff / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      let months = Math.floor(days / 30);
      let years = Math.floor(months / 12);

      var returnValue = '';

      if (years == 1) {
        returnValue = years + ' ' + this.tmService.translateKey('shared.time.year');
      } else if (years > 1) {
        returnValue = years + ' ' + this.tmService.translateKey('shared.time.years');
      } else if (months == 1) {
        returnValue = months + ' ' + this.tmService.translateKey('shared.time.month');
      } else if (months > 1) {
        returnValue = months + ' ' + this.tmService.translateKey('shared.time.months');
      } else if (days == 1) {
        returnValue = days + ' ' + this.tmService.translateKey('shared.time.day');
      } else if (days > 1) {
        returnValue = days + ' ' + this.tmService.translateKey('shared.time.days');
      } else if (hours == 1) {
        returnValue = hours + ' ' + this.tmService.translateKey('shared.time.hour');
      } else if (hours > 1) {
        returnValue = hours + ' ' + this.tmService.translateKey('shared.time.hours');
      } else if (minutes == 1) {
        returnValue = minutes + ' ' + this.tmService.translateKey('shared.time.minutes');
      } else if (minutes > 0) {
        returnValue = minutes + ' ' + this.tmService.translateKey('shared.time.minute');
      } else if (seconds <= 0) returnValue = '';
      else {
        returnValue = seconds + ' ' + this.tmService.translateKey('shared.time.seconds');
      }

      if (returnValue != '') {
        if (this.tmService.getCurrentLang() == 'fr') return this.tmService.translateKey('shared.time.ago') + ' ' + returnValue;
        else return returnValue + this.tmService.translateKey('shared.time.ago');
        //returnValue = this.tmService.translateKey('shared.time.ago', { time: returnValue });
      }
      return '';
    } catch (error) {
      console.log('Error in TimeElapsedPipe', error);
      return 'err';
    }
  }
}
