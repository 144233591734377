<!-- ----------------------------------------------------------------------------- -->
<!-- DIALOG : TITLE BAR -->
<!-- ----------------------------------------------------------------------------- -->

<kendo-dialog-titlebar (close)="cancel()">
  <div class="si-popup-titlebar-container">
    {{ (isNewTicketDetailMode ? "module.ticket-detail.edit.title_create" : "module.ticket-detail.edit.title_update") | translate }}
    @if(ticketDetail!.ticketId) { - Ticket #{{ ticketDetail!.ticketId }} }
  </div>
</kendo-dialog-titlebar>

<!-- ----------------------------------------------------------------------------- -->
<!-- DIALOG : CONTENT -->
<!-- ----------------------------------------------------------------------------- -->

<!-- LOADER -->
<app-loader *ngIf="isLoading"></app-loader>

<div class="si-dialog-edit-content">
  <!-- FIELDSET -->

  <fieldset class="si-edit-fieldset">
    <!-- RANGÉ #1 -->

    <div class="si-row">
      <!-- TECHNICIEN -->

      <div class="si-formfield" style="flex: 1">
        <kendo-label text="Tech: "></kendo-label>
        <kendo-dropdownlist
          [data]="technicianList"
          [valuePrimitive]="true"
          [textField]="'FullName'"
          [valueField]="'TechnicianCode'"
          [readonly]="!isNewTicketDetailMode"
          [class.si-input-readonly]="!isNewTicketDetailMode"
          [filterable]="true"
          [(ngModel)]="ticketDetail.scTechnicianCode"
          [kendoDropDownFilter]="technicianFilterSettings"
          (open)="onTechnicianDropdownOpen($event)"
          [disabled]="isLoading"
        >
        </kendo-dropdownlist>
      </div>

      <!-- DATE -->

      <div class="si-formfield" style="flex: 1">
        <kendo-label [text]="'module.fdt.list.label_date' | translate"> </kendo-label>
        <kendo-datepicker
          [(ngModel)]="ticketDetail.ticketDetailDate"
          placeholder="Choose a date ..."
          (blur)="onDateChanged()"
          (focus)="onDateFocus($event)"
          [disabled]="isLoading"
        ></kendo-datepicker>
      </div>

      <!-- LISTE DE CATEGORIES -->

      <div class="si-formfield" style="flex: 1">
        <kendo-label [text]="'module.ticket.model.scInvoicingCategoryCode' | translate" [style.width.px]="100"></kendo-label>
        <kendo-dropdownlist
          [data]="invoicingCategoryList"
          [valuePrimitive]="true"
          [textField]="'categoryCode'"
          [valueField]="'categoryCode'"
          [filterable]="true"
          [(ngModel)]="ticketDetail.scInvoicingCategoryCode"
          [kendoDropDownFilter]="invoiceCategoryFilterSettings"
          [disabled]="isLoading"
        >
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <ng-container *ngIf="dataItem">{{ ticketDetail.scInvoicingCategoryCode }}</ng-container>
          </ng-template>
        </kendo-dropdownlist>
      </div>
    </div>

    <!-- RANGÉ #2 -->

    <div class="si-row">
      <!-- ACTIVITY CODE -->

      <div style="flex: 1">
        <div class="si-formfield" style="margin-right: 5px">
          <kendo-label [text]="'module.ticket-detail.edit.label_activity' | translate"></kendo-label>
          <kendo-dropdownlist
            [data]="activityCodeList"
            [valuePrimitive]="true"
            textField="code"
            valueField="activityId"
            [filterable]="true"
            [(ngModel)]="ticketDetail.activityId"
            [kendoDropDownFilter]="activityCodeFilterSettings"
            [disabled]="isLoading"
          >
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <ng-container *ngIf="dataItem">{{ ticketDetail.activityId | getActivityCode : activityCodeList }}</ng-container>
            </ng-template>
          </kendo-dropdownlist>
        </div>
      </div>

      <!-- GROUPE HEURES -->

      <div style="flex: 1; display: flex">
        <!-- HEURES REEL -->

        <div class="si-formfield" style="flex: 1">
          <kendo-label [text]="'module.ticket-detail.edit.label_heure_reel' | translate"></kendo-label>
          <kendo-numerictextbox
            [(ngModel)]="ticketDetail.realWorkedHours"
            class="si-detail-item-input-time"
            [step]="0.25"
            [decimals]="2"
            [min]="0"
            [max]="24"
            [autoCorrect]="true"
            (blur)="onBlurRealWorkedHours()"
          />
        </div>

        <!-- HEURES FACTURABLE -->

        <div class="si-formfield" style="margin-left: 6px; margin-right: 5px; flex: 1">
          <kendo-label [text]="'module.ticket-detail.edit.label_heure_facturable' | translate"></kendo-label>
          <kendo-numerictextbox
            [(ngModel)]="ticketDetail.billableWorkedHours"
            [ngClass]="{ 'si-detail-item-input-time': true, 'si-billable-hours-egal-to-real': isHoursEqual() }"
            [step]="0.25"
            [decimals]="2"
            [min]="0"
            [max]="24"
            [autoCorrect]="true"
            (blur)="onBlurBillableWorkedHours()"
          />
        </div>
      </div>

      <!-- GROUPE DEPLACEMENTS -->

      <div style="flex: 1; display: flex">
        <!-- DEPLACEMENTS REEL -->

        <div class="si-formfield" style="flex: 1">
          <kendo-label [text]="'module.ticket-detail.edit.label_deplacement_reel' | translate"></kendo-label>
          <kendo-numerictextbox
            [(ngModel)]="ticketDetail.realTravelledHours"
            class="si-detail-item-input-time"
            [step]="0.25"
            [decimals]="2"
            [min]="0"
            [max]="24"
            [autoCorrect]="true"
            (blur)="onBlurRealTraveledHours()"
          />
        </div>

        <!-- DEPLACEMENTS FACTURABLE -->

        <div class="si-formfield" style="flex: 1">
          <kendo-label [text]="'module.ticket-detail.edit.label_deplacement_facturable' | translate" style="width: 80px"></kendo-label>
          <kendo-numerictextbox
            [(ngModel)]="ticketDetail.billableTravelledHours"
            [ngClass]="{ 'si-detail-item-input-time': true, 'si-billable-hours-egal-to-real': isDeplacementEqual() }"
            [step]="0.25"
            [decimals]="2"
            [min]="0"
            [max]="24"
            [autoCorrect]="true"
            (blur)="onBlurBillableTraveledHours()"
          />
        </div>
      </div>
    </div>

    <!-- COMMENTAIRE SERVICENTRE -->

    <div class="si-formfield">
      <kendo-label [text]="'module.ticket-detail.edit.label_comment_servicentre' | translate"></kendo-label>
      <textarea
        kendoTextBox
        [(ngModel)]="ticketDetail.servicentreComment"
        class="si-detail-item-input-comment-servicentre"
        resizable="auto"
        (blur)="copyComment()"
      ></textarea>
    </div>

    <!-- COMMENTAIRE PUBLIQUE -->
    <!--
    <div class="si-formfield">
      <kendo-label [text]="'module.ticket-detail.edit.label_comment_public' | translate"></kendo-label>
      <kendo-editor [(ngModel)]="ticketDetail.publicComment" [resizable]="true" class="si-detail-item-input-comment-public" (paste)="onPaste($event)" [iframe]="false"></kendo-editor>
    </div>
    -->

    <!-- COMMENTAIRE INTERNE -->

    <div class="si-formfield">
      <kendo-label [text]="'module.ticket-detail.edit.label_comment_internal' | translate"></kendo-label>
      <kendo-editor
        [(ngModel)]="ticketDetail.internalComment"
        [resizable]="true"
        class="si-detail-item-input-comment-internal"
        (paste)="onPaste($event)"
        [iframe]="false"
      ></kendo-editor>
    </div>

    <input type="hidden" id="ticketId" name="ticketId" [(ngModel)]="ticketDetail.ticketId" />
  </fieldset>
</div>

<!-- ----------------------------------------------------------------------------- -->
<!-- DIALOG : FOOTER -->
<!-- ----------------------------------------------------------------------------- -->

<kendo-dialog-actions>
  <!-- BOUTON CANCEL -->
  <button kendoButton [svgIcon]="allIcons.xIcon" fillMode="outline" (click)="cancel()" type="button">{{ "shared.button.cancel" | translate }}</button>
  @if(!isNewTicketDetailMode) {
  <!-- BOUTON DELETE -->
  <button kendoButton [svgIcon]="allIcons.trashIcon" themeColor="error" (click)="delete()" type="button" [disabled]="isLoading">
    {{ "shared.button.delete" | translate }}
  </button>
  }
  <!-- BOUTON SAVE -->
  <button kendoButton [svgIcon]="allIcons.saveIcon" themeColor="primary" (click)="onSubmit()" type="button" [disabled]="isLoading">
    {{ "shared.button.save" | translate }}
  </button>
</kendo-dialog-actions>
