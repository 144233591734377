import { Pipe, PipeTransform } from '@angular/core';
/**
 * Retourne l'information sur le code d'activité. Par défaut on retourne le code
 */
@Pipe({
  name: 'getActivityCode',
})
export class ActivityCodePipe implements PipeTransform {
  transform(activityId: any, activityCodeList: any, fieldToReturn: string = 'code'): string {
    // chercher le nom si la liste de client n'est pas vide
    if (activityCodeList) {
      let name = activityCodeList
        .filter((c: any) => c.activityId == activityId)
        .map((c: any) => {
          if (fieldToReturn === 'description') return c.description ?? '';
          else return c.code;
        });
      if (name && name.length > 0) {
        return name[0];
      }
    }
    // on retourne le activityId si on ne trouve pas le nom (j'ai vu des activityId == -1 dans la DB)
    return activityId <= 0 ? '' : activityId;
  }
}
