import { Injectable, TemplateRef, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable, of } from 'rxjs';
import { mergeMap, tap, map, switchMap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State, toODataString } from '@progress/kendo-data-query';
import { DialogService, DialogRef, DialogCloseResult, WindowService, WindowRef, DialogAction } from '@progress/kendo-angular-dialog';

import { TranslationManagementService } from 'src/app/core/translation/translate.service';
import { TicketHeader } from './ticket-header.model';
import { UtilService } from 'src/app/shared/util.service';
import { LocalStorage } from 'src/app/core/local-storage.enum';
import { TicketHeaderListComponent } from './ticket-header-list/ticket-header-list.component';

const apiUrl = environment.apiUrl;

//const ticketStatus = ['À CÉDULER', 'COMPLETE', 'COMPLÉTÉ', 'EN ATTENTE', 'EN COMMANDE', 'EN TRAITEMENT', 'FACTURE', 'NOUVEAU', 'PLANIFIÉ', 'PRÊT À LIVRER'];

@Injectable({
  providedIn: 'root',
})
export class TicketHeaderService {
  private tickets: ReplaySubject<TicketHeader[]> = new ReplaySubject<TicketHeader[]>(1);
  public ticket: ReplaySubject<TicketHeader> = new ReplaySubject<TicketHeader>(1);
  public ticketsOdata: ReplaySubject<any> = new ReplaySubject<any>(1);
  private ticketList: TicketHeader[] = []; // liste des tickets dans la cache
  private dialog!: DialogRef;
  private window!: WindowRef;

  private windowService = inject(WindowService);
  private translateService = inject(TranslationManagementService);

  constructor(private http: HttpClient, private dialogService: DialogService, private utilService: UtilService) {}

  initOne() {
    this.ticket.next(new TicketHeader());
  }

  list(): Observable<TicketHeader[]> {
    let uri = `${apiUrl}services/Ticket`;
    return this.http.get<TicketHeader[]>(uri).pipe(
      mergeMap((response: any) => {
        let value = response.value;
        this.tickets.next(value);
        return of(value);
      })
    );
  }

  read(id: number): Observable<TicketHeader> {
    return this.http.get<TicketHeader>(apiUrl + 'services/Ticket/' + id, {}).pipe(
      tap((response: any) => {
        this.ticket.next(response);
      })
    );
  }

  /*
  create(ticket: any): Observable<any> {
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    return this.http.post<any>(apiUrl + 'services/Ticket/', ticket).pipe(
      tap((response: any) => {
        //this.ticket.next(response); // TODO: réactiver quand l'API va me retourner le record comme dans un get
        // TODO : refresh de la variable this.tickets avec le nouveau record
      })
    );
  }

  update(ticket: any): Observable<any> {
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    return this.http
      .put<any>(apiUrl + 'services/Ticket/' + ticket.ticketId, ticket)
      .pipe(
        tap((response: any) => {
          //this.ticket.next(response); // TODO: réactiver quand l'API va me retourner le record comme dans un get
        })
      );
  }
  */

  // CACHE

  /**
   * cette fonction retourne la liste de client mis en cache dans le localStorage.
   * si  la cache n'A pas été rafraichie depuis 20 secondes, on la rafraichie.
   * on fetch les données de l'API de facon recursive pour obtenir tous les records.
   * @param forceFetch
   * @returns la liste de tous les clients
   */

  listCache(forceFetch?: boolean): Observable<TicketHeader[]> {
    let fetch = false;
    // read localStorage
    let ls = localStorage.getItem(LocalStorage.CACHE_TICKET);
    if (forceFetch) {
      fetch = true;
    } else if (ls != null && ls.length > 0) {
      let data = JSON.parse(ls!);
      let now = Date.now();
      // force to refresh every X seconds
      if (now - data.lastUpdate > 20000) {
        fetch = true;
      }
    } else {
      // empty localstorage
      fetch = true;
    }
    // fetch or return cache data
    if (fetch) {
      // reset cache et start fetching
      localStorage.removeItem(LocalStorage.CACHE_TICKET);
      return this.fetchOData(apiUrl + 'services/Ticket?$select=noClient,nomClient,nomPersonneContact,telephone1,ville,adresse,estActif');
    } else {
      let data = JSON.parse(ls!);
      // update service variable
      this.ticketList = data.data;
      return of(data.data);
    }
  }

  /*
   * cette fonction fetch les données de l'API de facon recursive pour obtenir tous les records.
   */
  private fetchOData(url: string): Observable<TicketHeader[]> {
    let uri = `${url}`;
    return this.http.get<any>(uri).pipe(
      switchMap((response) => {
        const data = response.value as TicketHeader[];
        // fetch actual cache & update it
        let existingCache = JSON.parse(localStorage.getItem(LocalStorage.CACHE_TICKET)!) || { lastUpdate: 0, data: [] };
        existingCache.data = existingCache.data.concat(data);
        existingCache.lastUpdate = Date.now();
        localStorage.setItem(LocalStorage.CACHE_TICKET, JSON.stringify(existingCache));
        // update service variable
        this.ticketList = existingCache.data;
        // fetch next page if needed OR return data
        if (response['@odata.nextLink']) {
          return this.fetchOData(response['@odata.nextLink']).pipe(map((nextData) => data.concat(nextData)));
        } else {
          return of(data);
        }
      }),
      catchError((error) => {
        console.error('Error fetching data:', error);
        return of([]);
      })
    );
  }

  // ODATA : STATE

  odata(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${apiUrl}services/Ticket?${toODataString(_state)}&$count=true`;

    return this.http.get<TicketHeader>(uri).pipe(
      tap((response: any) => {
        this.ticketsOdata.next(response);
      })
    );
  }

  // ODATA  : STRING

  odataStr(state: State, oDataString: string): Observable<any> {
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    let uri = `${apiUrl}services/Ticket?${oDataFullString}&$count=true`;
    return this.http.get<any>(uri);
  }

  /**
   * Permet d'afficher un popup pour choisir un ticket.  retournera un objservable avec le ID du ticket.
   * @returns
   */
  public popupChooseTicket(): Observable<any> {
    const confirmObservable = new Observable((observer) => {
      //*************************************
      //on prépare les libellés de nos actions (entete, choisir et cancel)
      let strTitrePopup = this.translateService.translateKey('module.ticket.popup.title');
      let strCancelButton = this.translateService.translateKey('module.ticket.popup.button_cancel');
      let strACtionButton = this.translateService.translateKey('module.ticket.popup.button_select');
      //*************************************

      let myActions: DialogAction[] = [{ text: strCancelButton }, { text: strACtionButton, themeColor: 'primary' }];

      //*************************************
      //On fabrique le composant de Dialog
      this.dialog = this.dialogService.open({
        content: TicketHeaderListComponent, //injection ici d'un composant au complet
        title: strTitrePopup,
        height: '80%',
        width: '80%',
        cssClass: 'si-dialog-grid',
        actions: myActions, //on peut aussi assigner direct ici: [{ text: strCancelButton }, { text: strACtionButton, themeColor: 'primary' }]
      });
      //*************************************

      //*************************************
      //ici, on instancie le composant, et on lui dit qu'on est en mode popup
      const dialogData = this.dialog.content.instance as TicketHeaderListComponent;
      dialogData.isPopupMode = true; //très important!  Sinon le composant "navigue" d'un ticket à l'autre
      //*************************************

      //*************************************
      //on déclenche ici l'affichage du Dialog
      this.dialog.result.subscribe((dialogResult) => {
        let strDialogResult = JSON.stringify(dialogResult);
        //si on ferme la fenêtre (Escape pour le X dans le Title Bar...)
        if (dialogResult instanceof DialogCloseResult) {
          observer.next(false); //rien..
        } else {
          //si le bouton clické n'a pas le texte du bouton d'action [donc 'Selection ce ticket'] on ne fait rien
          if (dialogResult.text != strACtionButton) {
            observer.next(false); //rien..
          }
          //si c'est pas un cancel, on va chercher le ticket choisi, et on retournera ça
          let dialogData = this.dialog.content.instance as TicketHeaderListComponent;
          let chooosen = dialogData.currentSelectedTicketId;
          // confirm
          observer.next(chooosen);
        }
      });
      //*************************************
    });
    return confirmObservable;
  }
}
