<kendo-dialog-titlebar (close)="close()">
  <div class="si-popup-titlebar-container">
    {{ title | translate }}
  </div>
</kendo-dialog-titlebar>
<!-- CONTENT -->
<div class="si-dialog-content" id="message"></div>
<!-- BUTTONS -->
<kendo-dialog-actions>
  <button kendoButton themeColor="base" (click)="ok()" class="si-pop-alert-button">
    {{ "shared.button.ok" | translate }}
  </button>
</kendo-dialog-actions>
