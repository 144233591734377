import { Pipe, PipeTransform } from '@angular/core';
import { Department } from 'src/app/module/service/department/department.model';
/**
 * Retourne le nom du client
 */
@Pipe({
  name: 'getDepartmentName',
})
export class DepartmentNamePipe implements PipeTransform {
  transform(idDepartment: number, customerList: Department[], fieldToReturn: string): string {
    // chercher le nom si la liste de client n'est pas vide

    let ValueToReturn = `${idDepartment} - departement inconnu`;

    if (customerList) {
      let name = customerList
        .filter((c: Department) => c.departmentId == idDepartment)
        .map((c: Department) => {
          if (fieldToReturn === 'nameFr') ValueToReturn = c.nameFr;
          if (fieldToReturn === 'nameEn') ValueToReturn = c.nameEn ?? '';
          if (fieldToReturn === 'departmentShortCode') ValueToReturn = c.departmentShortCode ?? '';

          return c.departmentShortCode + ' - ' + c.nameFr;
        });
    }

    return ValueToReturn;
  }
}
