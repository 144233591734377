// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import pkg from '../../package.json';

export const environment = {
  production: false,
  appVersion: pkg.version + '-dev',
  apiUrl: 'https://dev.api.solutionsoutien.solutioninfomedia.com/api/',

  sso_microsoft: {
    /** Il s'agit de l'applicationID de votre App enregistré dans Azure*/
    MSEntraApplicationID: '5301b759-c47f-4582-8a1a-93f5ab08abfb',

    /** Chemin que l'on veut que Microsoft prenne quand le Login SSO a fonctionné, pour revenir vers l'app */
    MSOidcRedirectUri: 'https://dev.solutionsoutien.solutioninfomedia.com/auth/signin-ms-oidc',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
