<div class="si-list-container">
  @if(!isPopupMode) {
  <div class="si-list-title">
    <span>{{ "module.ticket.list.title" | translate }} </span>
  </div>
  }

  <kendo-grid
    #myMainGrid
    [data]="gridView"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="pageable"
    [filterable]="true"
    [resizable]="true"
    [selectable]="gridService.selectableSettings"
    scrollable="scrollable"
    [loading]="isLoading"
    class="si-list-grid"
    (cellClick)="onCellClick($event)"
    (dataStateChange)="dataStateChange($event)"
    [rowSelected]="isRowSelected"
    [reorderable]="true"
    [columnMenu]="true"
    (columnVisibilityChange)="onColumnVisibilityChange($event)"
  >
    <!-- TOOLBAR -->
    <ng-template kendoGridToolbarTemplate position="top">
      <div class="si-list-actionbar-container">
        <!-- LISTE DE BOUTONS PRESET -->
        <div class="si-list-actionbar-left">
          @if(!isPopupMode) {
          <!-- BOUTON RECHERCHER COMMENTAIRES -->
          <button
            kendoButton
            [svgIcon]="allIcons.searchIcon"
            themeColor="primary"
            fillMode="solid"
            routerLink="/ticket/ticket-detail"
            type="button"
            [disabled]="isLoading"
          >
            {{ "module.ticket.list.button_search_comment" | translate }}
          </button>
          }
        </div>

        <div class="si-list-actionbar-right">
          <kendo-menu>
            <kendo-menu-item>
              <ng-template kendoMenuItemTemplate>
                <kendo-svg-icon [icon]="allIcons.listLatinBigIcon" themeColor="primary"></kendo-svg-icon>
                Preset
              </ng-template>
              <ng-template kendoMenuItemContentTemplate let-index="index">
                <div class="si-preset-menu-container">
                  <button kendoButton themeColor="primary" fillMode="outline" (click)="saveGridPreset()" type="button" [disabled]="isLoading">
                    Enregistrer votre Preset
                  </button>
                  <button
                    kendoButton
                    themeColor="primary"
                    fillMode="outline"
                    (click)="loadGridPreset(currentPresetName)"
                    type="button"
                    [disabled]="isLoading || !presetData?.state"
                  >
                    Recharger votre Preset
                  </button>
                </div>
              </ng-template>
            </kendo-menu-item>
          </kendo-menu>
        </div>
      </div>
    </ng-template>

    <!-- TICKET -->

    <kendo-grid-column field="ticketId" title="{{ 'module.ticket.model.ticketId' | translate }}" [width]="130" filter="numeric" format="n0"></kendo-grid-column>

    <!-- CLIENT -->

    <kendo-grid-column field="customerCode" title="{{ 'module.ticket.model.customer' | translate }}" [width]="270">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-dropdownlist
          [data]="customerList"
          [valuePrimitive]="true"
          [textField]="'nomClient'"
          [valueField]="'noClient'"
          [filterable]="true"
          [(ngModel)]="columnConfigDictionaryFilters['customerCode']"
          [kendoDropDownFilter]="customerFilterSettings"
          (selectionChange)="filterChange('customerCode', $event.noClient)"
        >
        </kendo-dropdownlist>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.customerCode | getCustomerName : customerList }}</span>
      </ng-template>
    </kendo-grid-column>

    <!-- TITLE -->

    <kendo-grid-column field="scTicketShortTitle" title="{{ 'module.ticket.model.scTicketShortTitle' | translate }}" [width]="250"></kendo-grid-column>

    <!-- scTicketType (CATEGORIE) -->

    <kendo-grid-column field="scTicketType" title="{{ 'module.ticket.model.scTicketType' | translate }}" [width]="160">
      <ng-template kendoGridFilterCellTemplate let-column="column" let-filter="filter">
        <kendo-multiselect
          textField="ServicentreTextualCode"
          valueField="ServicentreTextualCode"
          [checkboxes]="true"
          [autoClose]="false"
          [data]="ticketTypeList"
          [(ngModel)]="columnConfigDictionaryFilters['scTicketType']"
          (valueChange)="filterMultiSelectChange($event, 'TicketTypeId', 'TicketTypeId')"
          [kendoDropDownFilter]="ticketTypeFilterSettings"
          [filterable]="true"
          class="si-grid-filter-multiselect"
        ></kendo-multiselect>
      </ng-template>
    </kendo-grid-column>

    <!-- ASSIGNED TO -->

    <kendo-grid-column field="sC_Ticket_AssignedTo_UserCode" title="{{ 'module.ticket.model.sC_Ticket_AssignedTo_UserCode' | translate }}" [width]="190">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-dropdownlist
          [data]="technicianList"
          [valuePrimitive]="true"
          [textField]="'FullName'"
          [valueField]="'TechnicianCode'"
          [filterable]="true"
          [(ngModel)]="columnConfigDictionaryFilters['sC_Ticket_AssignedTo_UserCode']"
          [kendoDropDownFilter]="ticketTypeFilterSettings"
          (selectionChange)="filterChange('sC_Ticket_AssignedTo_UserCode', $event.TechnicianCode)"
        >
        </kendo-dropdownlist>
      </ng-template>
      <!-- VALUE -->
      <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.sC_Ticket_AssignedTo_UserCode | getTechnicianName : technicianList }} </ng-template>
    </kendo-grid-column>

    <!-- CREATED BY -->

    <kendo-grid-column field="sC_Ticket_CreatedBy_UserCode" title="{{ 'module.ticket.model.sC_Ticket_CreatedBy_UserCode' | translate }}" [width]="190">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-dropdownlist
          [data]="technicianList"
          [valuePrimitive]="true"
          [textField]="'FullName'"
          [valueField]="'TechnicianCode'"
          [filterable]="true"
          [(ngModel)]="columnConfigDictionaryFilters['sC_Ticket_CreatedBy_UserCode']"
          [kendoDropDownFilter]="ticketTypeFilterSettings"
          (selectionChange)="filterChange('sC_Ticket_CreatedBy_UserCode', $event.TechnicianCode)"
        >
        </kendo-dropdownlist>
      </ng-template>
      <!-- VALUE -->
      <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.sC_Ticket_CreatedBy_UserCode | getTechnicianName : technicianList }} </ng-template>
    </kendo-grid-column>

    <!-- STATUT -->

    <kendo-grid-column field="scTicketStatusCode" title="{{ 'module.ticket.model.scTicketStatusCode' | translate }}" [width]="130">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-multiselect
          textField="ServicentreTextualCode"
          valueField="ServicentreTextualCode"
          [checkboxes]="true"
          [autoClose]="false"
          [data]="ticketStatusList"
          [(ngModel)]="columnConfigDictionaryFilters['scTicketStatusCode']"
          [kendoDropDownFilter]="ticketStatusFilterSettings"
          (valueChange)="filterMultiSelectChange($event, 'TicketStatusId', 'TicketStatusId')"
          class="si-grid-filter-multiselect"
        ></kendo-multiselect>
      </ng-template>
    </kendo-grid-column>

    <!-- CONTRAT DE SERVICE -->

    <kendo-grid-column field="serviceContractId" title="{{ 'module.ticket.model.serviceContractId' | translate }}" [width]="150" filter="numeric" format="n0">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem.serviceContractId != 0">{{ dataItem.serviceContractId }}</span>
      </ng-template>
    </kendo-grid-column>

    <!-- CATEGORIE DE FACTURATION -->

    <kendo-grid-column field="scInvoicingCategoryCode" title="{{ 'module.ticket.model.scInvoicingCategoryCode' | translate }}" [width]="150">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-multiselect
          textField="categoryCode"
          valueField="categoryCode"
          [checkboxes]="true"
          [autoClose]="false"
          [data]="invoicingCategoryList"
          [(ngModel)]="columnConfigDictionaryFilters['scInvoicingCategoryCode']"
          [kendoDropDownFilter]="invoicingCategoryFilterSettings"
          (valueChange)="filterMultiSelectChange($event, 'scInvoicingCategoryCode', 'categoryCode')"
          class="si-grid-filter-multiselect"
        ></kendo-multiselect>
      </ng-template>
    </kendo-grid-column>

    <!-- billableSubTotalAmount  -->

    <kendo-grid-column
      field="billableSubTotalAmount"
      title="{{ 'module.ticket.model.billableSubTotalAmount' | translate }}"
      [width]="110"
      filter="numeric"
      format="#.## $"
    ></kendo-grid-column>

    <!-- departmentId  -->

    <kendo-grid-column field="departmentId" title="{{ 'module.ticket.model.departmentId' | translate }}" [width]="130">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-dropdownlist
          [data]="listAvailableDepartements"
          [valuePrimitive]="true"
          [textField]="'departmentShortCode'"
          [valueField]="'departmentId'"
          [(ngModel)]="columnConfigDictionaryFilters['departmentId']"
          (selectionChange)="filterChange('departmentId', $event.departmentId)"
        >
        </kendo-dropdownlist>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.departmentId | getDepartmentName : listAvailableDepartements : "departmentShortCode" }}</span>
      </ng-template>
    </kendo-grid-column>

    <!-- DATE OUVERTURE -->

    <kendo-grid-column field="openingDate" title="{{ 'module.ticket.model.openingDate' | translate }}" [width]="140" filter="date">
      <!-- FILTER (LOWER OR EQUAL THAN PAR DEFAUT )-->
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"> </kendo-grid-date-filter-cell>
      </ng-template>
      <!-- VALUE -->
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.openingDate | date : "yyyy-MM-dd" }}
      </ng-template></kendo-grid-column
    >

    <!-- ISCLOSED -->

    <kendo-grid-column field="isClosed" title="{{ 'module.ticket.model.isClosedOrOpen' | translate }}" [width]="100">
      <!-- FILTER -->
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <div class="ss-grid-filter-container">
          <kendo-dropdownlist
            [data]="[
              { text: 'shared.button.all' | translate, value: '' },
              { text: 'module.ticket.isClosed.closed' | translate, value: true },
              { text: 'module.ticket.isClosed.opened' | translate, value: false }
            ]"
            [valuePrimitive]="true"
            [textField]="'text'"
            [valueField]="'value'"
            [(ngModel)]="columnConfigDictionaryFilters['isClosed']"
            [filterable]="false"
            (valueChange)="filterChange('isClosed', $event)"
          >
          </kendo-dropdownlist>
        </div>
      </ng-template>
      <!-- CELL -->
      <ng-template kendoGridCellTemplate let-dataItem>
        @if(dataItem.isClosed) {
        <span class="ticket-list-text-isClosed">{{ "module.ticket.isClosed.closed" | translate }}</span>
        } @else {
        <span class="ticket-list-text-isOpened">{{ "module.ticket.isClosed.opened" | translate }}</span>
        }
      </ng-template>
    </kendo-grid-column>

    <!-- COMMANDS COLUMN

    <kendo-grid-command-column [width]="140">
      <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
        <button kendoButton type="button" themeColor="primary" fillMode="flat" [svgIcon]="allIcons.searchIcon" (click)="showTicketComments($event, dataItem)">
          {{ "module.ticket.list.button_ticket_comment" | translate }}
        </button></ng-template
      >
    </kendo-grid-command-column>
     -->
  </kendo-grid>
</div>
