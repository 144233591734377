import { Component } from '@angular/core';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { TranslationManagementService } from 'src/app/core/translation/translate.service';

@Component({
  selector: 'app-pop-alert',
  templateUrl: './pop-alert.component.html',
  styleUrls: ['./pop-alert.component.scss'],
})
export class PopAlertComponent extends DialogContentBase {
  title = '';
  message = '';

  constructor(public override dialog: DialogRef, private translateService: TranslationManagementService) {
    super(dialog);
  }

  ngOnInit() {
    let message = this.translateService.translateKey(this.message);
    let messageContainer = document.getElementById('message');
    if (messageContainer) {
      messageContainer.innerHTML = message;
    }
  }

  close() {
    this.dialog.close(false);
  }

  ok() {
    this.dialog.close(true);
  }
}
