<kendo-dialog-titlebar (close)="cancel()">
  <div class="si-popup-titlebar-container">
    {{ title | translate }}
  </div>
</kendo-dialog-titlebar>
<!-- CONTENT -->

<div class="si-popup-container">
  <kendo-grid
    [data]="gridView"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="pageable"
    [filterable]="true"
    [resizable]="true"
    [selectable]="gridService.selectableSettings"
    scrollable="scrollable"
    [loading]="isLoading"
    class="si-list-grid"
    (cellClick)="select($event)"
    (dataStateChange)="dataStateChange($event)"
  >
    <!-- COLUMNS -->

    <!-- SERVICE CONTRACT ID -->

    <kendo-grid-column
      field="serviceContractId"
      title="{{ 'module.service-contract.model.serviceContractId' | translate }}"
      [width]="80"
      filter="numeric"
    ></kendo-grid-column>

    <!-- CUSTOMER CODE -->

    <kendo-grid-column field="customerCode" title="{{ 'module.service-contract.model.customer' | translate }}" [width]="110">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-dropdownlist
          [data]="customerList"
          [valuePrimitive]="true"
          [textField]="'nomClient'"
          [valueField]="'noClient'"
          [filterable]="true"
          [kendoDropDownFilter]="customerFilterSettings"
          (selectionChange)="filterChange('customerCode', $event.noClient)"
        >
        </kendo-dropdownlist>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.customerCode | getCustomerName : customerList }}</span>
      </ng-template>
    </kendo-grid-column>

    <!-- CATEGORY (CONTRACT TYPE) -->

    <kendo-grid-column field="scContractType" title="{{ 'module.service-contract.model.scContractType' | translate }}" [width]="100"></kendo-grid-column>

    <!-- DATE DE DEBUT -->

    <kendo-grid-column field="contractStartDate" title="{{ 'module.service-contract.model.contractStartDate' | translate }}" [width]="80" filter="date">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.contractStartDate | date : "yyyy-MM-dd" }}
      </ng-template>
    </kendo-grid-column>

    <!-- DATE ECHEANCE -->

    <kendo-grid-column field="contractEndDate" title="{{ 'module.service-contract.model.contractEndDate' | translate }}" [width]="80" filter="date">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.contractEndDate | date : "yyyy-MM-dd" }}
      </ng-template>
    </kendo-grid-column>

    <!-- FREQUENCE -->

    <kendo-grid-column field="scInvoicingMode" title="{{ 'module.service-contract.model.scInvoicingMode' | translate }}" [width]="80"></kendo-grid-column>

    <!-- NOTE INTERNE -->

    <kendo-grid-column field="internalShortNote" title="{{ 'module.service-contract.model.internalShortNote' | translate }}" [width]="160"></kendo-grid-column>

    <!-- MONTANT -->

    <kendo-grid-column
      field="periodTotalAmt"
      title="{{ 'module.service-contract.model.periodTotalAmt' | translate }}"
      [width]="80"
      filter="numeric"
      format="#.## $"
    ></kendo-grid-column>
  </kendo-grid>
</div>
