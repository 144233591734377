{
  "name": "solution-soutien",
  "version": "1.1.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host=127.0.0.1",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "solinfo_Commentes": [
    "************************ COMMENTAIRE SOLINFO sur PACKAGE.JSON *********************",
    "Validez toujours si vos packages sont tolérants [^] ou non [~] aux mises à jour",
    "Mettez * sur les packages qu'on est prêt à toujours aller au plus à jour [le moins possible]"
  ],
  "dependencies": {
    "@angular/animations": "^17.0.0",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/localize": "^17.0.0",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@auth0/angular-jwt": "*",
    "@azure/msal-angular": "^3.0.13",
    "@azure/msal-browser": "^3.10.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@progress/kendo-angular-buttons": "14.3.0",
    "@progress/kendo-angular-common": "14.3.0",
    "@progress/kendo-angular-dateinputs": "^14.0.0",
    "@progress/kendo-angular-dialog": "14.3.0",
    "@progress/kendo-angular-dropdowns": "14.3.0",
    "@progress/kendo-angular-editor": "^14.3.0",
    "@progress/kendo-angular-excel-export": "^14.0.0",
    "@progress/kendo-angular-grid": "^14.0.0",
    "@progress/kendo-angular-icons": "14.3.0",
    "@progress/kendo-angular-indicators": "^14.0.0",
    "@progress/kendo-angular-inputs": "14.3.0",
    "@progress/kendo-angular-intl": "14.3.0",
    "@progress/kendo-angular-l10n": "14.3.0",
    "@progress/kendo-angular-label": "14.3.0",
    "@progress/kendo-angular-layout": "14.3.0",
    "@progress/kendo-angular-menu": "^14.0.0",
    "@progress/kendo-angular-navigation": "^14.0.0",
    "@progress/kendo-angular-notification": "^14.0.0",
    "@progress/kendo-angular-pdf-export": "^14.0.0",
    "@progress/kendo-angular-popup": "14.3.0",
    "@progress/kendo-angular-progressbar": "14.3.0",
    "@progress/kendo-angular-tooltip": "^14.0.0",
    "@progress/kendo-angular-treeview": "14.3.0",
    "@progress/kendo-angular-upload": "^14.0.0",
    "@progress/kendo-angular-utils": "^14.0.0",
    "@progress/kendo-data-query": "^1.0.0",
    "@progress/kendo-drawing": "^1.17.2",
    "@progress/kendo-licensing": "^1.0.2",
    "@progress/kendo-svg-icons": "^2.0.0",
    "@progress/kendo-theme-default": "^7.0.0",
    "rxjs": "*",
    "tslib": "^2.0.0",
    "zone.js": "*",
    "@progress/kendo-angular-toolbar": "14.3.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.0",
    "@angular/cli": "^17.0.0",
    "@angular/compiler-cli": "^17.0.0",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.2.2"
  }
}
